import React, { useState } from 'react';
import FileComponent from './FileComponent';
import FileReadComponent from './FileReadComponent';
import Header from "./Header";

function ExistingAccount(props) 
{
    const [User, setUsers]= useState('');  // setting all users to dispaly in the drop down
    const [selectedUser, setSelectedUser] = useState(null);  // setting the selected user from dropdown
    const [selectedUserId, setSelectedUserId] = useState(''); // setting id of the user which is selected from dropdown (both case)
    const [selectedAccountname, setSelectedAccountname] = useState(''); // pass to filecomponent to display accountname in ui
    const [username, setUsername] = useState(''); //pass to filecomponent to display username in ui
    //const [noUser, setNoUser] = useState("");
    const [measurement, setMeasurement] = useState("");
    const [billingPlan, setBillingPlan] = useState([]);
    const [errors, setErrors] = useState([]);

    var remote= window.wialon.core.Remote.getInstance(); 
    function asyncRemoteCall(svc, param) 
    {
        return new Promise(function(resolve, reject) {
          remote.remoteCall(svc, param, function(err, data) {
                if (err) reject(err);
                else resolve(data);
            });
        });
    }

    const handleAccountChange = async (event) =>  // on selecting one of the account from dropdown
    {
        var selectedAccount = event.target.value; // get selected account
        //console.log(selectedAccount);
        if(selectedAccount !== undefined)
        {
            setSelectedAccountname(selectedAccount);
            //function calls
            selectUser(selectedAccount); // function call passing selected account
            // findBillingPlan(selectedAccount);
        }
        else
        {
            setErrors(prev => [...prev,`Please select an account to continue.`]);
        }    
    } //end of handleAccountChange

    async function selectUser(selectedAccount)
    {
        setUsers([]); // Clearing the user dropdown before fetching new users
        // getting all users belongs to selected account
        try
        {
           console.log(selectedAccount);
            var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"user","propName":"rel_billing_account_name","propValueMask":selectedAccount ? selectedAccount : null,
                      "sortType":"rel_billing_account_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
            console.log(unit);
            if(unit.items.length === 0)
            {
                setErrors(prev => [...prev,`We couldn't find any users for the  "${selectedAccount}" account. Please try selecting the account again.`]);
            }
            else
            {
              searchUser(unit); // function call passing users
              findBillingPlan(selectedAccount);
            }          
        }
        catch(error)
        {
          //console.log("Failed to get users: ", error);
          setErrors(prev => [...prev,`We're unable to find any users for the selected account, "${selectedAccount}". Please try selecting the account again.`]);
        }

        function searchUser(unit) // iterating though users to display them in dropdown
        {
              var users = unit.items.map(({ nm, id }) => (
                <option key={id} value={nm} data-id={id}>
                    {nm}
                </option>
              ));
              setUsers(users); // setting all users to dispaly in the drop down
          }
    } //end of selectUser

    async function findBillingPlan(selectedAccount)
    {
      var selectedAccountId;
      try
        {
            var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_resource","propName":"rel_billing_account_name","propValueMask":selectedAccount,
            "sortType":"rel_billing_account_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
            //console.log(unit);
            if(unit.items.length === 0)
            {
              setErrors(prev => [...prev,`We're unable to retrieve details for the selected account,"${selectedAccount}" , including the billing plan. Please try selecting the account again.`]);
            }
            else
            {
              selectedAccountId = unit.items[0].id;
              //console.log(selectedAccountId);
              findPlan(selectedAccountId);
            }          
        }
        catch(error)
        {
            //console.log("Failed to get selected account detail: ", error);
            setErrors(prev => [...prev,`We're unable to retrieve details for the selected account, "${selectedAccount}". Please try selecting the account again.`]);
        }

        async function findPlan(selectedAccountId){
          try
          {
              var unit13 = await asyncRemoteCall("account/get_account_data",{"itemId":selectedAccountId,"type":1});
              //console.log(unit);
              if(unit13.plan !== undefined)
              {
                var billingPlanName= unit13.plan;
                setBillingPlan(billingPlanName);
              }
              else
              {
                setErrors(prev => [...prev,`We're unable to retrieve the billing plan for the selected account,"${selectedAccount}".Please try selecting the account again.`]);
              }
              
          }
          catch(error)
          {
              //console.log("Failed to get billing plan of selected user: ", error);
              setErrors(prev => [...prev,`We're unable to retrieve the billing plan for the selected account,"${selectedAccount}".`]);
          }

        }

        
    }
      
  const handleUserChange = async (event) => // on selecting one of the user from dropdown
  {
        var currentUser = event.target.value;
        //setSelectedUser(currentUser); // setting the selected user from dropdown
        setUsername(currentUser);
        //var id= event.target.selectedOptions[0].dataset.id;
        //setSelectedUserId(id); // setting id of the user which is selected from dropdown

        try
        {
            var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"user","propName":"sys_name","propValueMask":currentUser,
                      "sortType":"sys_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
              
            //console.log(unit);
            if(unit.items.length === 0)
            {
                setErrors(prev => [...prev,`We're unable to find the measurement system for the selected user, "${currentUser}".Please try selecting the user again.`]);
            }
            else
            {
                var measureUnit = unit.items[0].mu; // getting measurement
                var measure;
                if(measureUnit === 0)
                {
                  measure = "Metric";
                }
                else if(measureUnit === 1)
                {
                  measure = "U.S.";
                }
                else if(measureUnit === 2)
                {
                  measure = "Imperial";
                }
                else if(measureUnit === 3)
                {
                  measure = "Metric with gallons"
                }
                //console.log(measure);
                if(measure !== undefined)
                {
                  setMeasurement(measure); 
                  //console.log(currentUser);
                  setSelectedUser(currentUser); // setting the selected user from dropdown
                  var id= event.target.selectedOptions[0].dataset.id;
                  setSelectedUserId(id); // setting id of the user which is selected from dropdown
                }                
            }                    
        }
        catch(error)
        {
          //console.log("Failed to get billing plan for users: ", error);
          setErrors(prev => [...prev,`We're unable to find the measurement system details for the selected user,"${currentUser}".`]);
        }                   
  } // end of handleUserChange
   
  const handleBackClick = () => 
  {
      props.onBackClick();
  };

  return (
      <div>
          {selectedUser  ? (                         
              <div>
                <FileComponent setExcelFile={props.setExcelFile} selectedAccountname={selectedAccountname} username={username}/>
                <FileReadComponent billingPlan ={billingPlan} measurement={measurement} excelFile={props.excelFile} selectedUserId={selectedUserId} loggedUser ={props.loggedUser}/>
              </div> 
              ) :(
              <>
                <center>
                <Header text="Existing Account Selection" />
                      <p style={{ fontWeight: 'bold', padding: '10px' }}>Please select an account and the corresponding user to continue.</p>

                      <select className='dropdown' onChange = {handleAccountChange}>
                          <option value = "" >Select Account</option>
                          {props.allAccountNames.map((item, index) => (
                            <option key = {index} value = {item.value}>{item}</option>
                          ))}
                      </select>
                                                      
                      {selectedAccountname && 
                        <select className='dropdown' onChange = {handleUserChange}>
                          <option value = "">Select User</option>
                          {User}
                        </select>} 
                        <br/>
                        <br/>
                        <br/>          
                  </center>

                  {errors.map((msg, index) => (
                        <div key={index} style={{ fontWeight: 'bold', padding: '10px' }}>{msg}</div>
                  ))}
              </> 
            )}
            <button className ="Back" onClick= {handleBackClick}>Back to main menu</button>
        </div>
    );
}

export default ExistingAccount;