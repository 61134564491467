import React, { useState } from "react";
import FileComponent from "./FileComponent";
import FileReadComponent from "./FileReadComponent";
import ExistingAccount from "./ExistingAccount";
import Header from "./Header";
import PacmanLoader from "react-spinners/PacmanLoader";
//Accounts Wlp
import LVMNotifMetric from '../AccountWlp/LVM Notifications Metric.json';
import LVMNotifUS from '../AccountWlp/LVM Notifications US.json';
//UserWlp
import LVMUserMetric from '../UserWlp/LVM User Interface Default Metric.json';
import LVMUserUS from '../UserWlp/LVM User Interface Default US.json';


function CreateAccount(props)
{
        const [clickedCreate, setClickedCreate] = useState(false); // only displaying file and fileread components when its true (create account case)
        const [password] = useState("User1234!"); // set password to autogenerate in the form,
        const [inputFieldValues, setinputFieldValues] = useState({
            Measurement: 'Metric' // Set the default value
        }); //used in return form
        const [selectedUserId, setSelectedUserId] = useState(""); // setting id of the user which is selected from dropdown (both case)
        const [selectedAccountname, setAccountname] = useState(""); // pass to filecomponent to display accountname in ui
        const [username, setUsername] = useState(""); //pass to filecomponent to display username in ui
        const [accountexists, setAccountAlreadyExists] = useState(""); // account already exists error message while creating new accounts
        const [userexists, setUserAlreadyExists] = useState(""); // user already exists error message while creating new user
        const [billingPlan, setbillingPlan] = useState("");
        const [measurement, setMeasurement] = useState("");
        const [idForPresets, setIdForPresets] = useState(""); // to use in wlp component
        const [adminResourceId, setAdminResourceId] = useState(""); // to use in wlp component
        const [usernameForWlp, setUserNameForWlp] = useState(""); // to use in wlp component
        const [passwordForWlp, setPasswordForWlp] = useState(""); // to use in wlp component
        const [allAccountNames, setAccountName]= useState([]); // retrieved all accounts of logged user to create dropdown  and set it to Name and passing to ExistingAccount componnet
        //const [noAccounts, setNoAccounts] = useState("");
        const [goToExisting, setGoToExisting] = useState(false);
        const [loading,setLoading] =useState(false);
        const [AdminId, setAdminId] = useState("");
        const [DispatcherId, setDispatcherId] = useState("");
        const [ManagerId, setManagerId] = useState("");
        const [errors, setErrors] = useState([]);

        const [afterCreate,setAfterCreate] =useState(false);
        const [success, setSuccess] = useState([]);

        var adminId;
        var dispatcherId;
        var managerId;

        var remote = window.wialon.core.Remote.getInstance(); 
        function asyncRemoteCall(svc, param) 
        {
            return new Promise(function(resolve, reject) {
                remote.remoteCall(svc, param, function(err, data) {
                    if (err) reject(err);
                    else resolve(data);
                });
            });
        }

        const handleCreateAccountSubmit = async (event) => // on submitting form of creating new account
        {         
            event.preventDefault();
            const loggedUserid = window.wialon.core.Session.getInstance().getCurrUser().getId(); //get id of logged user
            console.log(loggedUserid);
            const username = inputFieldValues.UserName; // inputFieldValues from the form and taking user entered username
            //console.log(username);
            const accountName = inputFieldValues.AccountName; // inputFieldValues from the form and taking user entered account name
            //console.log(accountName);
            const selectedBilling = inputFieldValues.Billing;
            //console.log(selectedBilling);
            //console.log(props.billingPlan);                      
            try
            {
                //searching the entered accountname to check whether its an existing account
                var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_resource","propName":"rel_billing_account_name","propValueMask":accountName,
                    "sortType":"rel_billing_account_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
                console.log(unit);
                if(unit.items.length > 0)
                {
                    alert(`The account "${accountName}" already exists! Please select it from the existing accounts section.`);
                    setGoToExisting(true);

                    let accounts = [];
                    try
                    { 
                        // get all accounts under current login   
                        var unit1 = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_resource","propName":"rel_billing_account_name","propValueMask":"*",
                                        "sortType":"rel_billing_account_name","propType":"list","or_logic":0},"force":0,"flags":1,"from":0,"to":0});

                        //console.log(unit);
                        if(unit1.totalItemsCount === 0)
                        {
                            //setLoading(false);
                            setErrors(prev => [...prev,`We couldn't find any accounts associated with the currently logged in user.`]);                             
                        }
                        else
                        {
                            searchAccount(unit1); 
                        }                            
                    }
                    catch(error)
                    {
                        //setLoading(false);
                        setErrors(prev => [...prev,"We're unable to retrieve all accounts associated with the current login."]);                        
                    }           
                        
                    // iterate though all the retrieved accounts and create drop down list
                    function searchAccount(unit)
                    {
                        for(var i=0; i<unit.items.length; i++)
                        {
                            var accountName = unit.items[i].nm;
                            accounts.push(accountName);                          
                        }
                        var sortedArray = accounts.sort(Intl.Collator().compare);
                        setAccountName(sortedArray); // set generated accounts to "name" and iterate though it to genearte accounts dropdown
                    }
                }               
            }
            catch(error)
            {
                //setLoading(false);
                setErrors(prev => [...prev,"We're unable to verify if the provided account already exists."]);
            }

            // if it is not an existing account, then proceed to create new account
            var wordPresent = username.toLowerCase().includes("admin"); // check whether username contains admin word in it.If yes, returns true
            setLoading(true);

            if (accountName.length < 4)
            {
                setLoading(false);
                setAccountAlreadyExists("Please ensure the account name is at least 4 characters long.");
            }
            if (username.length < 4) 
            {
                setLoading(false);
                setAccountAlreadyExists("Please ensure the user name is at least 4 characters long.");
            } 
            if(wordPresent === false)
            {
                setLoading(false);
                setAccountAlreadyExists("Please ensure the username ends with '- admin'.");
            }
            if((accountName.length >= 4) && (username.length >= 4) && (wordPresent === true))
            {
                // Making this code to execute concurrently to reduce the time taken to create new account
                //setLoading(true);
                try {
                    const [unitX, unitY] = await Promise.all([
                        asyncRemoteCall("core/check_unique",{"type": "avl_resource", "value": accountName }),
                        asyncRemoteCall("core/check_unique",{"type": "user", "value": username })
                    ]);

                    var valueA = unitX["result"];
                    var valueB = unitY["result"];
                    if (valueA === 1) {
                        setLoading(false);
                        setAccountAlreadyExists(`The account named "${accountName}" already exists!`);
                    }

                    if (valueB === 1) {
                        setLoading(false);
                        setUserAlreadyExists(`The username "${username}" is already taken. Please try a different one.`);
                    }

                    // if ((valueA === 0) && (valueB === 0)) {
                    //     // Rest of the code...
                    // }
                } catch(error) {
                    setLoading(false);
                    setErrors(prev => [...prev,"We're unable to verify if the entered account name or username is unique."]);
                }

                // //setLoading(true);
                // try
                // {
                //     var unitX = await asyncRemoteCall("core/check_unique",{"type": "avl_resource", "value": accountName });
                //     //console.log(unitX["result"]);
                //     var valueA = unitX["result"];
                //     if (valueA === 1) 
                //     {
                //         setLoading(false);
                //         setAccountAlreadyExists(`The account named "${accountName}" already exists!`);
                //     }
                // }
                // catch(error)
                // {
                //     //console.log("Failed to get accounts: ", error);
                //     setLoading(false);
                //     setErrors(prev => [...prev,"We're unable to verify if the entered account name is unique."]);
                // }
                    
                // try
                // {
                //     var unitY = await asyncRemoteCall("core/check_unique",{"type": "user", "value": username });
                //     //console.log(unitY["result"]);
                //     var valueB = unitY["result"];
                //     //console.log(valueB);
                //     if (valueB === 1)
                //     {
                //         setLoading(false);
                //         setUserAlreadyExists(`The username "${username}" is already taken. Please try a different one.`);
                //     }
                // }
                // catch(error)
                // {
                //     setLoading(false);
                //     setErrors(prev => [...prev,"We're unable to verify if the entered user name is unique."]);
                // }
                                    
                // if ((valueA && valueB) === 0)
                // {
                //     //setClickedCreate(false);
                // }

                if ((valueA === 0) && (valueB === 0)) 
                {
                    setUserNameForWlp(username);
                    setPasswordForWlp(password);
                    
                    var measureofCreatedUser;
                    try
                    {
                        var unitA = await asyncRemoteCall("core/create_user",{"creatorId": loggedUserid, "name": username,"password": password, "dataFlags": 1});
                        console.log(unitA);
                        var CreatedUserId = unitA.item.id;
                        if(CreatedUserId !== undefined)
                        {
                            measureofCreatedUser = unitA.item.mu;
                            //console.log(measureofCreatedUser);
                            setSuccess(prev => [...prev,`Successfully created a new user with the username "${username}"`]);
                            setSelectedUserId(CreatedUserId); // setting the newly created user id
                            setIdForPresets(CreatedUserId);
                        }
                        else{
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We're unable to create a new user with the username "${username}"`]); 
                        }                       
                    }
                    catch(error){
                        setLoading(false);
                        setAfterCreate(true);
                        setErrors(prev => [...prev,`We encountered an issue while creating the new user "${username}"`]);
                    } 

                    // Providing "can create objects/items" access for user
                    try
                    {
                        var unit2 = await asyncRemoteCall("user/update_user_flags",{"userId": CreatedUserId, "flags": 4,"flagsMask": 0}); 
                        //console.log(unit2);
                        if (Object.keys(unit2).includes('fl')) {
                            setSuccess(prev => [...prev,` Successfully granted 'Create Items' access to the user "${username}"`]);
                        }
                        else{
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We're unable to grant 'Create Items' access to the user "${username}".`]);
                        }

                    }
                    catch(error)
                    {
                        setLoading(false);
                        setAfterCreate(true);
                        setErrors(prev => [...prev,`We encountered an issue while providing 'Create Items' access to the user "${username}".`]);
                    }
                    
                    setAccountname(accountName);
                    setUsername(username);
                    var m;
                    //console.log(measureofCreatedUser);
                    if(measureofCreatedUser === 0)
                    {
                        m = "Metric";
                    }
                    else if (measureofCreatedUser === 1) 
                    {
                        m = "U.S.";
                    } 
                    else if (measureofCreatedUser === 2)
                    {
                        m = "Imperial";
                    }
                    else if (measureofCreatedUser === 3) 
                    {
                        m = "Metric with gallons";
                    }
                    //console.log(m);
                    const selectedMeasure = inputFieldValues.Measurement;
                    //console.log(selectedMeasure);
                    var value;

                    if (selectedMeasure === "Metric")
                    {
                        value = 0;
                        
                    } else if (selectedMeasure === "U.S.") 
                    {
                        value = 1;
                        
                    } else if (selectedMeasure === "Imperial")
                    {
                        value = 2;                       
                    }
                    else if (selectedMeasure === "Metric with gallons") 
                    {
                        value = 3;                        
                    }

                    console.log(value);
                    if(value !== measureofCreatedUser){ // only update measurement if it is different from the measurement of created user.
                        try
                        {
                            if (m !== selectedMeasure){
                                var unitM = await asyncRemoteCall("item/update_measure_units",{ "itemId": CreatedUserId, "type": value, "flags": 0 });     
                                //console.log(unitM);
                                if (Object.keys(unitM).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`We're unable to update the measurement system for the user "${username}".`]);
                                }
                                else{
                                    setSuccess(prev => [...prev,` Successfully updated the measurement system for the user "${username}"`]);
                                }   
                            }
                            
                        }
                        catch(error)
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while updating the measurement system for the user.`]);
                        } 
                    }
                    //creating new resource
                    try
                    {
                        var unitB = await asyncRemoteCall("core/create_resource", { "creatorId": CreatedUserId, "name": accountName, "dataFlags": 1 });      
                        //console.log(unitB);
                        //console.log(unitB.item.mu);
                        var resourceid = unitB.item.id;
                        if(resourceid !== undefined)
                        {
                            setAdminResourceId(resourceid);
                            setSuccess(prev => [...prev,` Successfully created a new resource named "${accountName}".`]);
                        }
                        else
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We're unable to create a new resource named "${accountName}"`]);
                        }
                        
                    }
                    catch(error)
                    {
                        setLoading(false);
                        setAfterCreate(true);
                        setErrors(prev => [...prev,`We encountered an issue while creating new resource "${accountName}"`]);
                    } 

                    //creating new account
                    try
                    {
                        var unitC = await asyncRemoteCall("account/create_account",{ "itemId": resourceid, "plan": selectedBilling });   
                        //console.log(unitC);
                        if (Object.keys(unitC).length !== 0) {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We're unable to create the new account named "${accountName}".`]);
                        }
                        else{
                            setbillingPlan(selectedBilling);
                            setMeasurement(selectedMeasure);
                            setSuccess(prev => [...prev,` Successfully created a new account named "${accountName}".`]);
                        }                       
                    }
                    catch(error)
                    {
                        setLoading(false);
                        setAfterCreate(true);
                        setErrors(prev => [...prev,`We encountered an issue while creating the new account "${accountName}".`]);
                    } 
                                                                   
                    if (unitA !== null && unitB !== null && unitC !== null)
                    {                       
                        await cloneAndCreateUser(CreatedUserId,username,password,resourceid,selectedMeasure,selectedBilling,value,measureofCreatedUser);
                        if((selectedMeasure === "Imperial") || (selectedMeasure === "Metric with gallons"))
                        {
                            setErrors(prev => [...prev,`We're unable to apply the notifications WLP file for users having the "${selectedMeasure}" measurement system.`]);
                        }
                        else
                        {
                            await AccountWlp(accountName,resourceid,selectedMeasure);
                        }                        
                        setLoading(false); // loading icon stops after every function completes execution
                        if((CreatedUserId !== undefined) && (selectedBilling !== undefined) && (selectedMeasure !== undefined) &&
                        (props.loggedUser !== undefined) && (resourceid !== undefined) && (adminId !== undefined) &&
                        (dispatcherId !== undefined) && (managerId !== undefined))
                        {
                            // setClickedCreate(true);
                            setAfterCreate(true);
                        }
                    }

                    async function cloneAndCreateUser(userId,username,password,resourceid,selectedMeasure,selectedBilling,value,measureofCreatedUser)
                    {                       
                        var firstName = username.toLowerCase().split(/admin\b/);
                        var firstword = firstName[0];

                        var secondName = "Template";
                        var thirdNameAdmin = "Admin";
                        var thirdNameDispatcher = "Dispatcher";
                        var thirdNameManager = "Manager";
                        
                        // creating Template Admin User
                        var adminname = firstword + ' ' + secondName + ' ' + thirdNameAdmin;        
                        try
                        {
                            var unit = await asyncRemoteCall("core/create_user",{"creatorId": userId, "name": adminname,"password": password, "dataFlags": 1});
                            adminId = unit.item.id;
                            if(adminId !== undefined){
                                setAdminId(adminId);
                                setSuccess(prev => [...prev,` Successfully created admin user named "${adminname}".`]);
                            }
                            else
                            {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We're unable to create an admin user with the name "${adminname}"`]);
                            }                            
                        }
                        catch(error) 
                        {
                            setLoading(false);
                            setErrors(prev => [...prev,`We encountered an issue while trying to create an admin user "${adminname}"`]);
                        }
                        
                        // Providing create items access for admin user
                        try
                        {
                            var unit3 = await asyncRemoteCall("user/update_user_flags",{"userId": adminId, "flags": 4,"flagsMask": 0});
                            //console.log(unit3); 
                            if (Object.keys(unit3).includes('fl')) {
                                setSuccess(prev => [...prev,` Successfully granted 'Create Items' access to the user "${adminname}"`]);
                            }
                            else{
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We're unable to grant 'Create Items' access to the user "${adminname}".`]);
                            }
                        }
                        catch(error)
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while granting 'create items' access to the admin user "${adminname}"`]);
                        }

                        // creating Template Dispatcher User           
                        var dispachername = firstword + ' ' + secondName + ' ' + thirdNameDispatcher;
                        try
                        {
                            var unit4 = await asyncRemoteCall("core/create_user",{"creatorId": userId, "name": dispachername,"password": password, "dataFlags": 1});
                            //console.log(unit);
                            dispatcherId = unit4.item.id;
                            if(dispatcherId !== undefined){
                                setDispatcherId(dispatcherId);
                                setSuccess(prev => [...prev,` Successfully created dispatcher user named "${dispachername}".`]);
                            }
                            else
                            {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We're unable to create a dispatcher user with the name "${dispachername}"`]);
                            }
                        }

                        catch(error) 
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while trying to create a dispatcher user "${dispachername}"`]);
                        }
                                
                        // creating Template Manager User
                        var managername = firstword + ' ' + secondName + ' ' + thirdNameManager;
                        try
                        {
                            var unit5 = await asyncRemoteCall("core/create_user",{"creatorId": userId, "name": managername,"password": password, "dataFlags": 1});
                            //console.log(unit);
                            managerId = unit5.item.id;                            
                            if(managerId !== undefined){
                                setManagerId(managerId);
                                setSuccess(prev => [...prev,` Successfully created manager user named "${managername}".`]);
                            }
                            else
                            {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We're unable to create a manager user with the name "${managername}"`]);
                            }
                        }
                        catch(error) 
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while trying to create a manager user "${managername}"`]);
                        }

                        // if((userId !== undefined) && (selectedBilling !== undefined) && (selectedMeasure !== undefined) &&
                        // (props.loggedUser !== undefined) && (resourceid !== undefined) && (adminId !== undefined) &&
                        // (dispatcherId !== undefined) && (managerId !== undefined))
                        // {
                        //     // setClickedCreate(true);
                        // }

                        console.log(value);
                        console.log(adminId);
                        console.log(dispatcherId);
                        console.log(managerId);
                        if(adminId !== undefined && dispatcherId !== undefined && managerId !== undefined)
                        {
                            if(value !== measureofCreatedUser){
                                await updateMeasurement(adminId,value,adminname);
                                await updateMeasurement(dispatcherId,value,dispachername);
                                await updateMeasurement(managerId,value,managername);
                            }
                        }
                        
                        // Update measurement for all 3 users:
                        async function updateMeasurement(userId,value, name){
                            try
                            {   
                                console.log(value);                        
                                var unitN = await asyncRemoteCall("item/update_measure_units",{ "itemId": userId, "type": value, "flags": 0 });     
                                console.log(unitN);
                                if (Object.keys(unitN).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`We're unable to update the measurement system for the user "${name}".`]);
                                }
                                else{
                                    setSuccess(prev => [...prev,` Successfully updated the measurement system for the user "${name}"`]);
                                }                                                   
                            }
                            catch(error)
                            {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We encountered an issue while updating the measurement system for the user "${name}".`]);
                            } 
                        }

                        if(selectedMeasure === "Metric")
                        {
                            await MetricUserWlp(CreatedUserId,username);
                            await MetricUserWlp(adminId,adminname);
                            await MetricUserWlp(dispatcherId,dispachername);
                            await MetricUserWlp(managerId,managername);
                        }
                        if (selectedMeasure === "U.S.")
                        {
                            await USUserWlp(CreatedUserId,username);
                            await USUserWlp(adminId,adminname);
                            await USUserWlp(dispatcherId,dispachername);
                            await USUserWlp(managerId,managername);
                        }
                        
                        if((selectedMeasure === "Imperial") || (selectedMeasure === "Metric with gallons"))
                        {
                            setErrors(prev => [...prev,`Cannot apply user WLP file for users having measurement system "${selectedMeasure}".`]);
                        }
                        await Userpresets(username,userId,adminId,dispatcherId,managerId,resourceid);                       
                    } // end of cloneAndCreateUser

                    async function MetricUserWlp(CreatedUserId,name)
                    {
                        var usr;
                        usr = LVMUserMetric;
                        /* Some values are taken from WLP file and some are hardcoded values based on discussion with Andrew on May 14 2024*/ 

                        // Basic
                        var tzDst = usr["tz,dst"];                  
                        var Tz = tzDst[0]; //time zone  
                        var Dst = tzDst[1]; //daylight saving time                     
                        // var FD = usr.locale.fd;  // Date and time format, Persian Calendar
                        // var WD = usr.locale.wd; // Monday-1 Sunday -7
                        var FD = "%m-%E-%Y_%I:%M %p";
                        var WD = 7;                      
 
                        var add = usr["addr_provider,webg_c,webg_c_id,city,umsp"];
                        var city = add[3]; // city
 
                        var show = usr["show_log,user_settings_hotkeys,msc,pnfs,evt_flags,hbacit,hpnl,inf_map,uschs"];
                        var evtflg = show[4]; // Play sound for events - 1   automatically dispaly popup events - 2
                        var usersetting = show[1];  // use keyboard shortcuts
                        var uschs = show[8];   // Render charts on server

                        // Additional information about unit
                        var urs = usr["ursstp,usuei,usdrva"];
                        // var Urstp =urs[0]; // tooltips
                        // var Usue = urs[1]; // worklist
                        var Urstp = "0x1489a";
                        var Usue = "0x1df9a";
                        // to uncheck all, values will be ursstp: 0x0 and usuei: 0x0
                        //based on wlp ursstp 0x1df9a, tooltip - last msg time, icon, loc, presence in geo, speed, counter, coordinates, sensor value, custom fieds, profile, maintencence, drivers checked
                        // based on wlp usuei 0x1df9a, worklist - last msg time, icon, loc, presence in geo, speed, counter, coordinates, sensor value, custom fieds, profile, maintencence, drivers checked
                        var Usdrva = urs[2]; // Driver activity by online data

                        // Unit Visualization
                        var last = usr["mfrl,muf,last_tail_points,last_tail_color,last_tail_width,mf_use_sensors,sens_color_tooltip"];
                        //var Mfrl = last[0]; // dont know what it is
                        // var Muf = last[1];
                        var Muf = "3075";
                         // The value of muf and corresponding options in UI when done from frontend-  replace units 2073,show units icon 2569,blur 9, 2057 removing
                         // display overlapping
                         // Based on wlp file, value 1027 will mark blur icon and dispaly overlapping units checked and the other two unchecked.
 
                        //  var lasttail = last[2]; // Points in trace
                        var lasttail = "50";
                         var lastttailcolor = last[3]; // Trace color
                         var lasttailwidth = last[4]; // Trace width
                         //var mfusesens = last[5]; // dont know what it is
                        var senscolor = last[6];  // Multicolour sensors in unit tooltips - values 0,1,2
 
                         // other items on map
                         var ag = usr["agrsn,znsn,znsrv,zlg,rtssn"];
                         //var agrsn = ag[0];  // dont know what it is 
                         var znsn = ag[1]; // Dispaly names of geofences on map
                         var znsrv = ag[2]; // Render geofences on server
                         var zlg = ag[3]; // Dispaly overlapping geofences in one icon
                         var rtssn = ag[4]; // Dispaly names of routes check points on map
 
                         //Map source
                         var uu = usr["umap,mtg,mtmyin2,mtve,wg3,mtyh,mtyahin,mtya,mtks,mtlux,mtnavm,mtgis2,mtgt,mtwikim,mtgom,mthere,mtosm,mtmapbox,mtagis,mtvisicom,mtowm,mtaeris"];
                        //  var mtg = uu[1]; // google map
                        var mtg = "1";
                        var mtv =uu[3]; // bing map
                        var mtwikm =uu[13]; // wikimapia map
                        var mgosm =uu[16]; // open street Map 
 
                         // geodata source
                         var geodata =usr["geodata_source"][0];  // geodata source
 
                         // Address format
                        //  var usaddrfrmt = usr["us_addr_fmt"][0];  // if the value is 7686868_11_12   11 is min city radius and 12 is max distce from unit
                        
                         var usaddrfrmt = "1489502208_10_5";
                         var sens = usr["__sensolator_resource_id,access_templates,autocomplete,drvsvlist,event_descr,forceAddedDashboardTabOnce,fpnl,gfrad,grouped_units_icon,hide_promotion_banner,hide_tl_promotion_tooltip,language,lastmsgl,logPanelSize,m_ge,m_gia,m_gu,m_ml,m_mm,m_mongr,m_monu,m_mt,m_rt,m_ui,m_un,minimap_zoom_level,mon_units_update_m,mongr,monu,monuei,monuexpg,monugr,monugv,monuv,msg_aw,msg_reversed,mtge,mtlb,mtlg,mtosmmp,mu_tbl_cols_sizes,mu_tbl_sort,mugow,muow,notify_block_account,radd,roul,round_sort,round_sort_order,route_provider,tagsvlist,tracks_player_show_google_panoram,tracks_player_show_params,tracks_player_show_sensors,trlsvlist,unit_extra_order,us_addr_ordr,used_hw,vsplit,waypoint_tools,znsvlist"];
                         var usaddr =sens[56]; // order of address
                         var gfrad =sens[7];  // Distance from unit to geofence
                         var grouped =sens[8];  // Icon for overlapping unit - UNIT VISUALIZATION
                         
                         // Coordinate format
                         var cfmt = usr["cfmt"][0]; 
                        
                            try
                            { 
                                var params = {
                                    "params": [
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "tz",
                                                "value": Tz ? Tz : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "dst",
                                                "value": Dst ? Dst : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "ursstp",
                                                "value": Urstp ? Urstp : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "usuei",
                                                "value": Usue ? Usue : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "usdrva",
                                                "value": Usdrva ? Usdrva : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "muf",
                                                "value": Muf? Muf : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "last_tail_points",
                                                "value": lasttail? lasttail : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "last_tail_color",
                                                "value": lastttailcolor? lastttailcolor : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "last_tail_width",
                                                "value": lasttailwidth? lasttailwidth : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "sens_color_tooltip",
                                                "value": senscolor? senscolor : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "znsn",
                                                "value": znsn? znsn : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "znsrv",
                                                "value": znsrv? znsrv : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "zlg",
                                                "value": zlg? zlg : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "rtssn",
                                                "value": rtssn? rtssn : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "city",
                                                "value": city? city : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "us_addr_fmt",
                                                "value": usaddrfrmt? usaddrfrmt : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "user_settings_hotkeys",
                                                "value": usersetting? usersetting : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "evt_flags",
                                                "value": evtflg? evtflg : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "uschs",
                                                "value": uschs? uschs : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "cfmt",
                                                "value": cfmt? cfmt : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtg",
                                                "value": mtg? mtg : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtve",
                                                "value": mtv? mtv : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtwikim",
                                                "value": mtwikm? mtwikm : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtosm",
                                                "value": mgosm? mgosm : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "geodata_source",
                                                "value": geodata? geodata : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "gfrad",
                                                "value": gfrad? gfrad : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "grouped_units_icon",
                                                "value": grouped? grouped : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "us_addr_ordr",
                                                "value": usaddr? usaddr : ""
                                            }
                                       }            
                                    ],
                                    "flags": 0
                                }

                            var unit8 = await asyncRemoteCall("core/batch",params);
                            console.log(unit8);
                            //console.log(" User wlp added successfully: ");
                            let errorObject = unit8.find(obj => obj.hasOwnProperty('error'));
                            if (errorObject) {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We are unable to apply the "Metric" User WLP file for the user "${name}"`]);
                            }
                            else{
                                setSuccess(prev => [...prev,` Successfully applied the "Metric" User WLP file for the user "${name}".`]);
                            }
                        }
                        catch(error)
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while applying the "Metric" User WLP file for the user "${name}"`]);
                        }
                       
                        try
                        {
                            var param = {"userId":CreatedUserId, "locale": {"fd": FD,"wd":WD }};
                            var unit6 = await asyncRemoteCall("user/update_locale",param);
                            //console.log(unit6);
                            if (Object.keys(unit6).includes('locale')) {
                                setSuccess(prev => [...prev,` Successfully applied the locale options for the user "${name}" with Metric user WLP file.`]);
                            }
                            else{
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We are unable to set the locale options for the user "${name}" with Metric user WLP file.`]);
                            
                            }
                        }
                        catch(error)
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while setting the locale options for the user "${name}" with Metric user WLP file.`]);
                            
                        }
                    }

                    async function USUserWlp(CreatedUserId,name)
                    {
                        var usr;
                        usr = LVMUserUS;
                       
                        // Basic
                        var tz = usr["tz,dst"];
                        var Tz = tz[0]; //time zone
                        var Dst = tz[1]; //daylight saving time
                        // var FD = usr.locale.fd; // date and time format , persian calendar
                        // var WD = usr.locale.wd; // first day of week
                        var FD = "%m-%E-%Y_%I:%M %p";
                        var WD = 7;                      
 
                        var web = usr["addr_provider,webg_c,webg_c_id,city,umsp"];
                        var city = web[3]; // city

                        var i = usr["show_log,user_settings_hotkeys,msc,pnfs,evt_flags,hbacit,hpnl,inf_map,uschs"];
                        var evtflg = i[4]; // Play sound for events - 1   automatically dispaly popup events - 2
                        var usersetting = i[1];  // use keyboard shortcuts
                        var uschs = i[8];   // Render charts on server

                         // Additional information about unit
                         var us = usr["ursstp,usuei,usdrva"];
                        //  var Urstp =us[0]; // tooltips
                        //  var Usue = us[1]; // worklist
                        var Urstp = "0x1489a";
                        var Usue = "0x1df9a";
                        // to uncheck all, values will be ursstp: 0x0 and usuei: 0x0
                        //based on wlp ursstp 0x1df9a, tooltip - last msg time, icon, loc, presence in geo, speed, counter, coordinates, sensor value, custom fieds, profile, maintencence, drivers checked
                        // based on wlp usuei 0x1df9a, worklist - last msg time, icon, loc, presence in geo, speed, counter, coordinates, sensor value, custom fieds, profile, maintencence, drivers checked
                        var Usdrva = us[2]; // Driver activity by online data
                        
                        // Unit Visualization
                        var mf = usr["mfrl,muf,last_tail_points,last_tail_color,last_tail_width,mf_use_sensors,sens_color_tooltip"];
                        //var Mfrl = mf[0]; // // dont know what it is 
                        // var Muf = mf[1]; 
                        var Muf = "3075";
                        // The value of muf and corresponding options in UI when done from frontend-  replace units 2073,show units icon 2569,blur 9, 2057 removing
                        // display overlapping
                        // Based on wlp file, value 1027 will mark blur icon and dispaly overlapping units checked and the other two unchecked.

                        //var lasttail = mf[2];// Points in trace
                        var lasttail = "50";

                        var lastttailcolor = mf[3]; // Trace color
                        var lasttailwidth = mf[4]; // Trace width
                        //var mfusesens = mf[5]; // // dont know what it is 
                        var senscolor = mf[6];  // Multicolour sensors in unit tooltips - values 0,1,2

                        // other items on map
                        var rt = usr["agrsn,znsn,znsrv,zlg,rtssn"];
                        //var agrsn = rt[0];  // dont know what it is 
                        var znsn = rt[1]; // Dispaly names of geofences on map
                        var znsrv = rt[2]; // Render geofences on server
                        var zlg = rt[3]; // Dispaly overlapping geofences in one icon
                        var rtssn = rt[4];  // Dispaly names of routes check points on map

                        //Map source
                        var map = usr["umap,mtg,mtmyin2,mtve,wg3,mtyh,mtyahin,mtya,mtks,mtlux,mtnavm,mtgis2,mtgt,mtwikim,mtgom,mthere,mtosm,mtmapbox,mtagis,mtvisicom,mtowm,mtaeris"];
                        // var mtg =map[1]; // google map
                        var mtg ="1";
                        var mtv =map[3]; // bing map
                        var mtwikm =map[13]; // wikimapia map
                        // var mgosm =map[16]; // open street Map 
                        var mgosm = "1";

                        // geodata source
                        var geodata =usr["geodata_source"][0];

                        // Address format
                        //var usaddrfrmt = usr["us_addr_fmt"][0];  // if the value is 7686868_11_12   11 is min city radius and 12 is max distce from unit
                        var usaddrfrmt = "1489502208_10_5";

                        var f = usr["forceAddedDashboardTabOnce,fpnl,language,mongr,monu,monuei,monuv,mu_tbl_cols_sizes,mugow,muow,notify_block_account,unit_extra_order,us_addr_ordr,znsvlist"];
                        var usaddrordr =f[12]; // order of address
                        
                        // Coordinate format
                        var cfmt = usr["cfmt"][0];

                        
                        try
                        {                           
                            var params = {
                                    "params": [
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "tz",
                                                "value": Tz ? Tz : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "dst",
                                                "value": Dst ? Dst : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "ursstp",
                                                "value": Urstp ? Urstp : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "usuei",
                                                "value": Usue ? Usue : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "usdrva",
                                                "value": Usdrva ? Usdrva : ""
                                            }
                                        },                                        
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "muf",
                                                "value": Muf? Muf : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "last_tail_points",
                                                "value": lasttail? lasttail : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "last_tail_color",
                                                "value": lastttailcolor? lastttailcolor : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "last_tail_width",
                                                "value": lasttailwidth? lasttailwidth : ""
                                            }
                                        },

                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "sens_color_tooltip",
                                                "value": senscolor? senscolor : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "znsn",
                                                "value": znsn? znsn : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "znsrv",
                                                "value": znsrv? znsrv : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "zlg",
                                                "value": zlg? zlg : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "rtssn",
                                                "value": rtssn? rtssn : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "city",
                                                "value": city? city : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "us_addr_fmt",
                                                "value": usaddrfrmt? usaddrfrmt : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "user_settings_hotkeys",
                                                "value": usersetting? usersetting : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "evt_flags",
                                                "value": evtflg? evtflg : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "uschs",
                                                "value": uschs? uschs : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "cfmt",
                                                "value": cfmt? cfmt : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtg",
                                                "value": mtg? mtg : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtve",
                                                "value": mtv? mtv : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtwikim",
                                                "value": mtwikm? mtwikm : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "mtosm",
                                                "value": mgosm? mgosm : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "geodata_source",
                                                "value": geodata? geodata : ""
                                            }
                                        },
                                        {
                                            "svc": "item/update_custom_property",
                                            "params": {
                                                "itemId": CreatedUserId,
                                                "name": "us_addr_ordr",
                                                "value": usaddrordr? usaddrordr : ""
                                            }
                                        },
                                    ],
                                    "flags": 0
                                }

                            var unit9 = await asyncRemoteCall("core/batch",params);
                            //console.log(unit);
                            let errorObj = unit9.find(obj => obj.hasOwnProperty('error'));
                            if (errorObj) {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We are unable to apply the "U.S." User WLP file for the user "${name}"`]);
                            }
                        }
                        catch(error)
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while applying the "U.S." User WLP file for the user "${name}"`]);
                        }
                        
                        try
                        {
                            var para = {"userId":CreatedUserId, "locale": {"fd": FD,"wd":WD }};
                            var unit7 = await asyncRemoteCall("user/update_locale",para);
                            //console.log(unit7);
                            if (Object.keys(unit7).includes('locale')) {
                                setSuccess(prev => [...prev,` Successfully applied the locale options for the user "${name}" with U.S. user WLP file.`]);
                            }
                            else{
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We are unable to set the locale options for the user "${name}" with U.S. user WLP file.`]);
                            
                            }
                        }
                        catch(error)
                        {
                            setLoading(false);
                            setAfterCreate(true);
                            setErrors(prev => [...prev,`We encountered an issue while setting the locale options for the user "${name}" with U.S. user WLP file.`]);                           
                        }
                    }

                    async function Userpresets(username,userId,adminId,dispatcherId,managerId,resourceId)
                    {
                        // Managing user rights for newly created 3 users
                        // User has full access to newly created 3 users
                        var params = {"userId":userId, "itemId":adminId,"accessMask": 7405567};
                        try
                        {
                                var unit30 = await asyncRemoteCall("user/update_item_access",params);
                                //console.log(unit);
                                if (Object.keys(unit30).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`Unable to assign admin presets to the user '${username}'. `]);
                                }  
                                else{
                                    
                                    setSuccess(prev => [...prev,`Successfully assigned admin presets to the user '${username}'.`]);
                                }
                        } 
                        catch(error)
                        {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We encountered an issue while granting admin access to the newly created user "${username}".`]);
                        }

                        var p = {"userId":userId, "itemId":dispatcherId,"accessMask": 7405567};
                        try
                        {
                                var unit31 = await asyncRemoteCall("user/update_item_access",p);
                                //console.log(unit8);
                                if (Object.keys(unit31).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`Unable to assign dispatcher presets to the user '${username}'.`]);
                                } 
                                else{
                                    
                                    setSuccess(prev => [...prev,`Successfully assigned dispatcher presets to the user '${username}'.`]);
                                }
                        } 
                        catch(error)
                        {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We encountered an issue while granting dispatcher access to the newly created user "${username}".`]);
                        }

                        var ps = {"userId":userId, "itemId":managerId,"accessMask": 7405567};
                        try
                        {
                                var unit32 = await asyncRemoteCall("user/update_item_access",ps);
                                //console.log(unit9);
                                if (Object.keys(unit32).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`Unable to assign manager presets to the user '${username}'.`]);
                                } 
                                else{
                                    
                                    setSuccess(prev => [...prev,`Successfully assigned manager presets to the user '${username}'.`]);
                                }
                        } 
                        catch(error)
                        {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We encountered an issue while granting manager access to the newly created user "${username}".`]);
                        }

                        // Admin user has full access rights on other users                               
                        var paramss = {"userId":adminId, "itemId":dispatcherId,"accessMask": 7405567};
                        try
                        {
                                var unit33 = await asyncRemoteCall("user/update_item_access",paramss);
                                //console.log(unit10);
                                if (Object.keys(unit33).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`Unable to assign dispatcher presets to the admin user '${username}'. `]);
                                } 
                                else{
                                    
                                    setSuccess(prev => [...prev,`Successfully assigned dispatcher presets to the admin user '${username}'.`]);
                                }
                        } 
                        catch(error)
                        {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We encountered an issue while granting dispatcher access to the newly created admin user "${username}". `]);
                        }

                        var ppp = {"userId":adminId, "itemId":managerId,"accessMask": 7405567};
                        try
                        {
                                var unit34 = await asyncRemoteCall("user/update_item_access",ppp);
                                //console.log(unit11);
                                if (Object.keys(unit34).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`Unable to assign manager presets to the admin user '${username}'. `]);
                                }
                                else{
                                    
                                    setSuccess(prev => [...prev,`Successfully assigned manager presets to the admin user '${username}'.`]);  
                                }                       
                        } 
                        catch(error)
                        {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We encountered an issue while granting manager access to the newly created admin user "${username}".`]);
                        }


                        //Dispatcher has no access to any other units. By default- No Access

                        // Manager has only access to dispatcher
                        var prm = {"userId":managerId, "itemId":dispatcherId,"accessMask": 6322163};
                        try
                        {
                               var unit35 = await asyncRemoteCall("user/update_item_access",prm);
                               //console.log(unit12);
                               if (Object.keys(unit35).length !== 0) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`Unable to assign dispatcher presets to the manager user '${username}'. `]);
                                } 
                                else{
                                    
                                    setSuccess(prev => [...prev,`Successfully assigned dispatcher presets to the manager user '${username}'.`]);
                                }
                        } 
                        catch(error)
                        {
                                setLoading(false);
                                setAfterCreate(true);
                                setErrors(prev => [...prev,`We encountered an issue while granting dispatcher access to the newly created manager user "${username}".`]);
                        }
                    }
                                      
                    async function AccountWlp(accountName,resourceid,selectedMeasure)
                    {
                            // Applying resource wlp to newly created resource
                            var notifications;
                            //console.log(LVMNotifMetric);
                            //console.log(LVMNotifUS);
                            //console.log(selectedMeasure);
                            if (selectedMeasure === "Metric")
                            {
                                notifications = LVMNotifMetric.notifications;
                            }
                            if(selectedMeasure === "U.S.")
                            {
                                notifications = LVMNotifUS.notifications;
                            }
                            
                            try
                            {                              
                                for (var notify = 0; notify < notifications.length; notify ++)
                                {                               
                                    var ItemId = resourceid; // Resource ID
                                    var notId = notifications[notify].id ? notifications[notify].id : 0; // Notification ID
                                    var CallMode = "create";
                                    var Name = notifications[notify].n ? notifications[notify].n : ""; // Notification Name
                                    var Txt = notifications[notify].txt ? notifications[notify].txt : ""; // Text of the notification
                                    var Ta = notifications[notify].ta ? notifications[notify].ta : 0; // Activation time
                                    var Td = notifications[notify].td ? notifications[notify].td : 0; // Deactivation time
                                    var Ma = notifications[notify].ma ? notifications[notify].ma : 0; // Maximal alarms count
                                    var Mmtd = notifications[notify].mmtd ? notifications[notify].mmtd : 0; // Maximal time interval between messages (seconds)
                                    var Cdt = notifications[notify].cdt ? notifications[notify].cdt :0; // Timeout of alarm
                                    var Mast = notifications[notify].mast ? notifications[notify].mast : 0; // minimal duration of alert state (seconds)
                                    var Mpst = notifications[notify].mpst ? notifications[notify].mpst : 0; // minimal duration of previous state (seconds)
                                    var Cp = notifications[notify].cp ? notifications[notify].cp : 0; // period of control relative to current time (seconds)
                                    var Fl =  notifications[notify].fl ? notifications[notify].fl : 0; // notification flags
                                    var Tz = notifications[notify].tz ? notifications[notify].tz : 0; // timezone 
                                    var La = notifications[notify].la ? notifications[notify].la : ""; // user language (two-lettered code)
                                    // In UI, notiifications can be linked to units or unit groups. But uisng this system since notifications are applied before inits creation, commenting this field
                                    //var Un = (notifications[notify].un.length > 0 ) ? notifications[notify].un : []; // array units/unit groups ID's
                                    var Un = []; // array units/unit groups ID's
                                    //console.log(Un);
                                    var Sch = notifications[notify].sch ? notifications[notify].sch : {"f1":0,"f2":0,"t1":0,"t2":0,"m":0,"y":0,"w":0}; // time limitation
                                    var Ctrl_sch = notifications[notify].ctrl_sch ? notifications[notify].ctrl_sch : {"f1":0,"f2":0,"t1":0,"t2":0,"m":0,"y":0,"w":0}; // maximal alarms count intervals shedule
                                    // var Trg_t = notifications[notify].trg.t ? notifications[notify].trg.t : ""; // control type
                                    // var Trg_p = notifications[notify].trg.p ? notifications[notify].trg.p : {"":""}; // control parameters
                                    var Trg = notifications[notify].trg ? notifications[notify].trg : {"t":"","p":{"":""}};
                                    var Act = (notifications[notify].act.length > 0) ? notifications[notify].act : [{"t":"","p":{}}]; // actions

                                    var params = {"itemId":ItemId,"id":notId,"callMode":CallMode,"n":Name,"txt":Txt,
                                    "ta":Ta,"td":Td,"ma":Ma,"mmtd":Mmtd,"cdt":Cdt,"mast":Mast,
                                    "mpst":Mpst,"cp":Cp,"fl":Fl,"tz":Tz,"la":La,"un":Un,"sch":Sch,
                                    "ctrl_sch":Ctrl_sch,"trg":Trg,"act":Act}
                                        
                                
                                    var unit36 = await asyncRemoteCall("resource/update_notification",params);
                                    //console.log(unit);
                                    var errfound = false;
                                    if (Array.isArray(unit36) && typeof unit36[0] === 'number' && typeof unit36[1] === 'object') {
                                        errfound = false;
                                    } 
                                    else{
                                        errfound = true;
                                    }

                                }  // end of for loop
                                if (errfound) {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`Unable to apply the notifications WLP file to the new account '${accountName}'.`]);
                                }
                                else{
                                    setSuccess(prev => [...prev,`Successfully applied the notifications WLP file to the new account '${accountName}'.`]);
                                }                             
                            }
                            catch(error) 
                            {
                                    setLoading(false);
                                    setAfterCreate(true);
                                    setErrors(prev => [...prev,`We encountered an issue while applying notifications WLP file to the new account '${accountName}'.`]);
                            } 
                            setLoading(false);                     
                    } // end of AccountWlp 
                }
            }
                        
        }
        const handleBackClick = () => 
        {
            props.onBackClick();
        };

        function handleDownloadClick() {
            // download the file to users system including errors and successfull messages
            let content = '';
            content += 'Account and User Creation Log:\n';
            content += '\n\n';
            content += 'Log:\n';
            errors.forEach((message) => 
                {
                    content += message + '\n';
                });
            content += '\n\n';
            content += 'Status:\n';
            success.forEach((message) =>
            {
                content += message + '\n';
            });
               
            //console.log("err",errors);
            //console.log("err",errorMessages);
            let blob = new Blob([content], { type: 'text/plain' });   // Create a new Blob with the error messages
            let url = URL.createObjectURL(blob); 
            let a = document.createElement('a');   // Create a new 'a' element
            a.href = url;             // Set the href and download attributes of the 'a' element
            a.download = 'AccountUserLog.txt';
            document.body.appendChild(a);   // Append the 'a' element to the body
            a.click();             // Simulate a click on the 'a' element
            document.body.removeChild(a); // Remove the 'a' element from the body
            setClickedCreate(true);
        }

  return (
    <div>
        {clickedCreate ? (
          <>
            <FileComponent setExcelFile={props.setExcelFile} selectedAccountname={selectedAccountname} username={username} loggedUser={props.loggedUser}/>
            <FileReadComponent excelFile={props.excelFile} selectedUserId={selectedUserId} billingPlan ={billingPlan} measurement={measurement} 
                      loggedUser={props.loggedUser} idForPresets ={idForPresets} adminResourceId ={adminResourceId}
                      usernameForWlp = {usernameForWlp} passwordForWlp = {passwordForWlp}
                      AdminId ={AdminId} DispatcherId = {DispatcherId} ManagerId = {ManagerId}/>
          </>
        ) : goToExisting ? (
            <ExistingAccount loggedUser ={props.loggedUser} allAccountNames={allAccountNames} excelFile={props.excelFile} setExcelFile={props.setExcelFile}/>
        ): loading ? (
            <center> 
                <PacmanLoader color={'#1063a9'} loading={loading} size={25}
                aria-label="Loading Spinner" data-testid="loader"/>
            </center>

        ): afterCreate? (
            <button style={{display: 'block', width: '400px', height: '100px', margin: '0 auto', fontSize: '20px',fontWeight: 'bold'}} onClick={handleDownloadClick}>Click to download the error log and proceed with the next step.</button>
        ):(  
          <>       
            <center>
                <div style={{ fontWeight: 'bold',color:'red', padding: '1px' }}>{accountexists}</div>
                <div style={{ fontWeight: 'bold', color:'red', padding: '10px' }}>{userexists}</div>
                {errors.map((msg, index) => (
                            <div key={index}style={{ fontWeight: 'bold', padding: '10px' }}>{msg}</div>
                ))}
                <br />
                <Header text="Creating New Account and Users" />
                <form onSubmit={handleCreateAccountSubmit} className="form">
                    <br />
                    <label className="label"> Account Name:
                        <input className="input" type="text" name="name" required onChange={(event) => setinputFieldValues({...inputFieldValues,AccountName: event.target.value,})}/>
                    </label>
                    <br />
                    <br />
                    <label className="label">User Name:
                        <input className="input" type="text" name="name" required onChange={(event) => setinputFieldValues({...inputFieldValues,UserName: event.target.value,})} />
                    </label>
                    <br />
                    <br />
                    <label className="label">User Password:
                        <input className="input" type="password" required value={password} readOnly />
                    </label>
                    <br />
                    <br />
                    <label className="label">Confirm Password:
                        <input className="input" type="password" required value={password} readOnly />
                    </label>
                    <br />
                    <br />
                    <label className="label">Creator of Account</label>
                    <select className="input" required defaultValue="option5">
                        <option value="option5">{props.loggedUser}</option>
                    </select>
                    <br />
                    <br />
                    <label className="label">Measurement System</label>
                    <select className="input" required onChange={(event) => setinputFieldValues({...inputFieldValues,Measurement: event.target.value})}>
                        <option>Metric</option>
                        <option>U.S.</option>
                        <option>Imperial</option>
                        <option>Metric with gallons</option>
                    </select>
                    <br />
                    <br />
                    <label className="label">Billing Plan</label>
                    <select className="input" required onChange={(event) => setinputFieldValues({...inputFieldValues,Billing: event.target.value})}>
                        <option></option>
                        {props.billingPlan.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <br />
                    <button className="submitButton" type="submit">Create Account & Users</button>
                </form>
            </center>
        </>
    )}
    <button className="Back" onClick={handleBackClick}>Back to main menu</button>
    </div>
    );
}

export default CreateAccount;
