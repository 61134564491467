import React, { useState } from "react";
import Accounts from "./Accounts";

const Login = () => 
{
  const [token, setToken] = useState(null); // To store token generated after login
  const [excelFile, setExcelFile] = useState(null); // stored in parent component and passed as props to children
  const [successfulLogin, setSuccessfulLogin] = useState(false); // login after token recieved
  const [loggedUser, setLoggedUser] = useState(""); // to get logged username and pass it to accounts component
  const [loginMessage, setLoginMessage] = useState(""); // to dispaly logged user in the UI
  const [noLoggedUser, setNoLoggedUser] = useState("");

  const dns = "https://hosting.wialon.com"; // Wialon site dns
  const handleRetrieveToken = () => 
  {  
    // access type is set as -1 to give complete access same as their login credentials
    const url = dns + "/login.html?client_id=App&access_type=-1&activation_time=0&duration=0&flags=0x1&redirect_uri=" + dns +"/post_token.html&css_url=https://webapps.astreon.com/index.css"; // constructing login page URL
    window.addEventListener("message", handleReceivedToken); // listen message with token from login page window
    window.open(url, "_blank", "width=760, height=500, top=300, left=500"); // open login page in new window

  };

  const handleReceivedToken = (e) => 
  {
    const origin = e.origin;
    //console.log(origin);
    // implementing a security check
    if (origin === 'https://hosting.wialon.com') 
    {
      //console.log(e.data);
      const msg = e.data; // get message from login window
      if (typeof msg === "string" && msg.indexOf("access_token=") >= 0) 
      {
        const token = msg.replace("access_token=", ""); // get token
        //console.log(token);
        setToken(token);
        setSuccessfulLogin(true); // login after token recieved

        window.wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com"); 
        window.wialon.core.Session.getInstance().loginToken(token,"",   //login to wialon using token
            function (code)
            {
              if (code)
                return;           
              const loggedUserName = window.wialon.core.Session.getInstance().getCurrUser().getName();
              console.log(loggedUserName);
              if(loggedUserName === undefined)
              {
                setNoLoggedUser("No user currently logged in")
              }
              else
              {
                setLoggedUser(loggedUserName);
                setLoginMessage(`Logged as ${loggedUserName}`); // to display logged user in the UI
              }
            }
        );
        window.removeEventListener("message", handleReceivedToken); // remove message event listener
      }
    }
  }; // end of handleReceivedToken

  const handleLogout = () =>  // on clicking logout button
  { 
    const sess = window.wialon.core.Session.getInstance();
    if (sess && sess.getId()) 
    {
      sess.logout(function () 
      {
        setSuccessfulLogin(false);
        setToken(null);
      });
    }

    // added to clear values when logout
    setToken("");
    setSuccessfulLogin("");
    setLoggedUser("");
    setLoginMessage("");
    setNoLoggedUser("");
  }; //end of logout


  return (
    <div>
      {!successfulLogin && (
        <div className="loginContainer">
          <h1 style={{fontStyle: 'italic', textAlign:'center'}} >Welcome to Onboarding Automation</h1>
          <button className="loginBtn" onClick={handleRetrieveToken}>Log in</button>
        </div>
      )}

      {token && (
        <div>
          <br />
          {successfulLogin && (
            <div>
              <strong> {loginMessage} </strong>
              {noLoggedUser}
              <div className="container">
                <p className="version">V 1.5</p>
              </div>
              <br />
              <button className="logoutBtn" onClick={handleLogout}>Log out</button>
              <br />
              <br />
              <Accounts loggedUser={loggedUser} excelFile={excelFile} setExcelFile={setExcelFile} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}; // end of login

export default Login;