import React,{useState} from 'react';
import * as XLSX from 'xlsx';
import uuid from 'react-uuid';
// to show loading symbols
import PacmanLoader from "react-spinners/PacmanLoader";

// wlp files to apply sensor, trip,eco driving, hardware configs, advanced props to units base on hardware type,measureemnt and billing plan
import AL11MetricPro from '../wlpFiles/ATrack AL11 Metric LVM ProPro+ Template.json';
import AL11MetricStd from '../wlpFiles/ATrack AL11 Metric LVM Standard Template.json'
import AL11USPro from '../wlpFiles/ATrack AL11 US LVM ProPro+ Template.json';
import AL11USStd from '../wlpFiles/ATrack AL11 US LVM Standard Template.json';
import AL11MetricVAR from '../wlpFiles/ATrack AL11 Metric VAR Template.json';
import AL11USVAR from '../wlpFiles/ATrack AL11 US VAR Template.json';
import AX11MetricPro from '../wlpFiles/ATrack AX11 Metric LVM ProPro+ Template.json';
import AX11MetricStd from '../wlpFiles/ATrack AX11 Metric LVM Standard Template.json';
import AX11MetricVAR from '../wlpFiles/ATrack AX11 Metric VAR Template.json'
import AX11USPro from '../wlpFiles/ATrack AX11 US LVM ProPro+ Template.json';
import AX11USStd from '../wlpFiles/ATrack AX11 US LVM Standard Template.json';
import AX11USVAR from '../wlpFiles/ATrack AX11 US VAR Template.json';
import BewhereMetricStd from '../wlpFiles/Bewhere Gateway Metric LVM Standard Template.json';
import BewhereMetricVAR from '../wlpFiles/Bewhere Gateway Metric VAR Template.json';
import BewhereUSStd from '../wlpFiles/Bewhere Gateway US LVM Standard Template.json';
import BewhereUSVAR from '../wlpFiles/Bewhere Gateway US VAR Template.json';
import GNXMetricPro from '../wlpFiles/GNX-6 Metric LVM ProPro+ Template.json';
import PT40MetricPro from '../wlpFiles/PT40 Metric LVM ProPro+ Template.json';
import PT40MetricStd from '../wlpFiles/PT40 Metric LVM Standard Template.json';
import PT40MetricVAR from '../wlpFiles/PT40 Metric VAR Template.json';
import PT40USPro from '../wlpFiles/PT40 US LVM ProPro+ Template.json';
import PT40USStd from '../wlpFiles/PT40 US LVM Standard Template.json';
import PT40USVAR from '../wlpFiles/PT40 US VAR Template.json';
import Quec600MetricStd from '../wlpFiles/Queclink GV600MG Metric LVM Standard Template.json';
import Quec600MetricVAR from '../wlpFiles/Queclink GV600MG Metric VAR Template.json';
import Quec600USStd from '../wlpFiles/Queclink GV600MG US LVM Standard Template.json';
import Quec600USVAR from '../wlpFiles/Queclink GV600MG US VAR Template.json';
import StarMetricPro from '../wlpFiles/STaRLinK AVL Metric LVM ProPro+Template.json';
import StarMetricStd from '../wlpFiles/STaRLinK AVL Metric LVM Standard Template.json';
import StreamMetricPro from '../wlpFiles/Streamax X3-H0402 Metric LVM ProPro+ Template.json';
import StreamMetricStd from '../wlpFiles/Streamax X3-H0402 Metric LVM Standard Template.json';
import StreamMetricVAR from '../wlpFiles/Streamax X3-H0402 Metric VAR Template.json';
import StreamUSPro from '../wlpFiles/Streamax X3-H0402 US LVM ProPro+ Template.json';
import StreamUSStd from '../wlpFiles/Streamax X3-H0402 US LVM Standard Template.json';
import StreamUSVAR from '../wlpFiles/Streamax X3-H0402 US VAR Template.json';
import TopDMetricPro from '../wlpFiles/Topflytech TLD2-D Metric LVM ProPro+ Template.json';
import TopDMetricStd from '../wlpFiles/Topflytech TLD2-D Metric LVM Standard Template.json';
import TopDMetricVAR from '../wlpFiles/Topflytech TLD2-D Metric VAR Template.json';
import TopDUSPro from '../wlpFiles/Topflytech TLD2-D US LVM ProPro+ Template.json';
import TopDUSStd from '../wlpFiles/Topflytech TLD2-D US LVM Standard Template.json';
import TopDUSVAR from '../wlpFiles/Topflytech TLD2-D US VAR Template.json';
import TopLMetricPro from '../wlpFiles/Topflytech TLD2-L Metric LVM ProPro+ Template.json';
import TopLMetricStd from '../wlpFiles/Topflytech TLD2-L Metric LVM Standard Template.json';
import TopLMetricVAR from '../wlpFiles/Topflytech TLD2-L Metric VAR Template.json';
import TopLUSPro from '../wlpFiles/Topflytech TLD2-L US LVM ProPro+ Template.json';
import TopLUSStd from '../wlpFiles/Topflytech TLD2-L US LVM Standard Template.json';
import TopLUSVAR from '../wlpFiles/Topflytech TLD2-L US VAR Template.json';
import TopLFMetricStd from '../wlpFiles/Topflytech TLP1-LF Metric LVM Standard Template.json';
import TopLFMetricVAR from '../wlpFiles/Topflytech TLP1-LF Metric VAR Template.json';
import TopLFUSStd from '../wlpFiles/Topflytech TLP1-LF US LVM Standard Template.json';
import TopLFUSVAR from '../wlpFiles/Topflytech TLP1-LF US VAR Template.json';
import TopSFMetricStd from '../wlpFiles/Topflytech TLP1-SF Metric LVM Standard Template.json';
import TopSFMetricVAR from '../wlpFiles/Topflytech TLP1-SF Metric VAR Template.json';
import TopSFUSStd from '../wlpFiles/Topflytech TLP1-SF US LVM Standard Template.json';
import TopSFUSVAR from '../wlpFiles/Topflytech TLP1-SF US VAR Template.json';
import Top12BLMetricPro from '../wlpFiles/Topflytech TLW2-12BL Metric LVM ProPro+ Template.json';
import Top12BLMetricStd from '../wlpFiles/Topflytech TLW2-12BL Metric LVM Standard Template.json';
import Top12BLMetricVAR from '../wlpFiles/Topflytech TLW2-12BL Metric VAR Template.json';
import Top12BLUSPro from '../wlpFiles/Topflytech TLW2-12BL US LVM ProPro+ Template.json';
import Top12BLUSStd from '../wlpFiles/Topflytech TLW2-12BL US LVM Standard Template.json';
import Top12BLUSVAR from '../wlpFiles/Topflytech TLW2-12BL US VAR Template.json';
import WhereMetricPro from '../wlpFiles/WhereQube OBD Metric LVM ProPro+ Template.json';
import WhereMetricStd from '../wlpFiles/WhereQube OBD Metric LVM Standard Template.json';
import WhereMetricVAR from '../wlpFiles/WhereQube OBD Metric VAR Template.json';
import WhereUSPro from '../wlpFiles/WhereQube OBD US LVM ProPro+ Template.json';
import WhereUSStd from '../wlpFiles/WhereQube OBD US LVM Standard Template.json';
import WhereUSVAR from '../wlpFiles/WhereQube OBD US VAR Template.json';
// new device types added
import TeltUSPro from '../wlpFiles/Teltonika FMM00A US LVM ProPro+ Template.json';
import TeltMetricPro from '../wlpFiles/Teltonika FMM00A Metric LVM ProPro+ Template.json';

function FileReadComponent(props) 
{
      //console.log(props);
      const [log, setLog] = useState([]);
      const [success, setSuccess] = useState([]);
      const [unitNames, setUnitNames] = useState([]);
      const [phoneNumber, setPhone] = useState([]);
      const [unitVIN, setVin] = useState([]);
      const [devicetype, setDeviceid] = useState([]);
      const [uid, setUid] = useState([]);
      //const [unittype, setUnittype] = useState([]);
      const [, setUnittype] = useState([]);
      const [vehicletype, setVehicletype] = useState([]);
      const [successCustom, setsuccessCustom] = useState([]);
      const [successUnitGroups, setsuccessUnitGroups] = useState([]);
      const [plate, setPlate] = useState([]);
      const [isLoading, setIsLoading] = useState(false);
      const [successSensors, setSuccessSensors] = useState([]);
      const [successHwConfig, setSuccessHwConfig] = useState([]);
      const [successMileage, setSuccessMileage] = useState([]);
      const [successEngine, setSuccessEngine] = useState([]);
      const [successAdvanced, setSuccessAdvanced] = useState([]);
      const [successTrip, setSuccessTrip] = useState([]);
      const [successEco, setSuccessEco] = useState([]);
      const [unitsCount, setUnitsCount] = useState([]);

    const handleUpload = async (event) =>
       {
            var IFTAinExcel;
            setIsLoading(true);
            event.preventDefault(); // prevent default behaviour
            const reader = new FileReader();  // create new instance of FileReader interface that gives methods to read the file from users system
            reader.onload = async (event) =>   // onload function to be called when the filereader finishes loading file
            {     
                const data = new Uint8Array(event.target.result); // creating new Uint8Array obj from result of filereader. dat is bonary string representing contents of file
                const workbook = XLSX.read(data, { type: 'array' });   // use xlsx library to parse binary data and Create a workbook object from the  data
                const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Get the first worksheet from workbook  object
                const excelData  = XLSX.utils.sheet_to_json(worksheet);  // Convert the worksheet  object to json an array of objects
                //console.log(excelData);
                if(excelData.length === 0)
                {
                    setLog("The uploaded Excel file appears to be empty. Please ensure the file contains data and try again.")
                }
                else
                {
                    //const { ['IFTA Reports Required ?']: IFTAinExcel} = excelData[0];                  
                    IFTAinExcel = excelData[0].IFTA;
                    //console.log(IFTAinExcel);
                    await init(excelData,IFTAinExcel); // call init function
                }               
            }; 
            reader.readAsArrayBuffer(props.excelFile); //starts the filereader Read the file got from props.excelfile as binary data
        } 

       var remote= window.wialon.core.Remote.getInstance();  //executing remote ajax request to server - if there is no corresponding js method
     
       async function init(excelData,IFTAinExcel)
       {
            var unitsCreatedCount = 0;
            var duplicateUIDCount = 0;
            var duplicatePhoneCount = 0;
            var validationSuccessful;           
            function asyncRemoteCall(svc, param) 
            {
                return new Promise(function(resolve, reject) 
                {
                    remote.remoteCall(svc, param, function(err, data) 
                    {
                        if (err) reject(err);
                        else resolve(data);
                    });
                });
            }   

            for (let i = 0; i < excelData.length; i++)  // iterate through items extracted from file
            { 
                const { 'Unit Name *': UnitName,'Unit Type *': UnitType,'Vehicle Type': VehicleType,VIN,'Device Type *':DeviceType, 
                        'Unique ID *':UniqueID, 'Phone Number' : Phone, 'Custom Field Name 1': customName1,'Custom Field Value 1':customValue1,
                        'Custom Field Name 2': customName2,'Custom Field Value 2':customValue2,'Custom Field Name 3': customName3,'Custom Field Value 3':customValue3,
                        'Custom Field Name 4': customName4,'Custom Field Value 4':customValue4,'Custom Field Name 5': customName5,'Custom Field Value 5':customValue5,
                        'Unit Group 1':UnitGroup1,'Unit Group 2':UnitGroup2,'Unit Group 3':UnitGroup3,'License Plate':LicensePlate,
                    } = excelData[i];

                const unitTypes =  //hardcoded unit types from wialon documentation
                {
                    "empty_person":"Person","empty_animal":"Animal","empty_personal_other":"Other", //Personal GPS tracker
                    "empty_camera":"Camera","empty_compressor":"Compressor","empty_container":"Container","empty_electric generator":"Electric generator", // Stationary object
                                        "empty_filling_station":"Filling station", "empty_machine_tool":"Machine-tool","empty_stationary_other": "Other",
                                        "empty_room":"Room","empty_vending_machine":"	Vending machine",
                    "pickup":"Pick-up","van":"Van","truck":"Truck","heavy_truck":"Heavy Truck","dump_truck":"Dump truck","logging_truck": "Logging Truck", //Cargo transport
                                          "tractor_unit":"Tractor unit","trailers_semi":"Trailer and semi-trailers","tanker_trailer":"Tanker trailer",
                                          "tank_trailer":"Tank truck","refrigerator_van":"Refrigerator van","other_cargo":"Other",
                    "kick_scooter":"Kick scooter","bicycle":"Bicycle","motorcycle":"Motorcycle","passenger_car": "Passenger car","minibus":"Minibus","bus":"Bus", //Passenger transport
                                    "trolleybus":"Trolleybus","tram":"Tram","other_passenger": "Other" ,
                    "tractor":"Tractor","combine":"Combine","sprayer":"Sprayer","baler":"Baler","agricultural_spreader":"Agricultural spreader","mower":"Mower", //Agricultural machinery
                    "bulldozer":"Bulldozer","excavator":"Excavator","backhoe_loader":"Backhoe loader","loader":"Loader","skid_steer_loader":"Skid-steer loader", //Construction equipment
                                      "forklift":"Forklift", "hooklift":"Hooklift","crane":"Cran","manipulator":"Manipulator","bucket_truck":"Bucket truck",
                                      "concrete_mixer":"Concrete mixer","concrete_pump":"Concrete pump", "piling_drilling_rig":"Piling and drilling rig","crusher":"Crusher",
                                      "trencher":"Trencher","pipelayer":"Pipelayer","road_roller":"Road roller", "road_grader":"Road grader","paver":"Paver","scraper":"Scraper",
                    "ambulance":"Ambulance","garbage_truck":"Garbage Truck","fire_truck":"Fire truck","street_cleaning_machine":"Street cleaning machine", //Special vehicles
                                      "tow_truck":"	Tow truck","other_special":"Other","store_on_wheels":"Store on wheels", 
                    "air_transport":"Air transport","off_road_vehicle":"Off-road vehicle","all_terrain_vehicle":"All-terrain vehicle","snowmobile":"Snowmobile", //Others
                                        "snowcat":"Snowcat","rail_transport":"	Rail transport", "water_transport":"Water transport",
                                        "recreational_vehicle":"Recreational vehicle","other_vehicle":"Other" 
                };

                // decalring variables globally
                var uniType;
                //var deviceid;
                //var phNumber;
                var phoneStr;
                var deviceid;
                var phNumber;
            
                await validate(duplicateUIDCount,duplicatePhoneCount,uniType,phoneStr,deviceid,phNumber,validationSuccessful);

                async function validate(duplicateUIDCount,duplicatePhoneCount,uniType,phoneStr,deviceid,phNumber,validationSuccessful) // validating all fields before creating units
                {                  
                    //Mandatory fields
                    if(UniqueID === undefined)
                    {
                        setLog(prev => [...prev,`The unit "${UnitName}" in the uploaded file does not have a unique id. Please check the file.`]);
                    }
                    if(!DeviceType)
                    {
                        setLog(prev => [...prev,`The unit "${UnitName}" in the uploaded file does not have an associated device type. Please check the file.`]);
                    }
                    if(!UnitName)
                    {
                        setLog(prev => [...prev,`The uploaded file does not contain a unit name. Please check the file.`]);
                    }

                    if(UniqueID !== undefined && DeviceType !== undefined && UnitName !== undefined)
                    {
                        // check whether file contains duplicate unique ids
                        function hasDuplicateUID(arr) 
                        {  
                            const uidCounts = {};
                            for (let j = 0; j < arr.length; j++) 
                            {
                                const uid = arr[j]["Unique ID *"];
                                if (uidCounts[uid])  // If this UID has already been encountered, it's a duplicate
                                {   
                                    return true;
                                } 
                                else // Otherwise, recording the count of this UID 
                                { 
                                    uidCounts[uid] = 1;
                                }
                            }
                            return false; // If looped through the entire array and didnt't found a duplicate,then there are no duplicates
                        }
                
                        if(hasDuplicateUID(excelData)) // if duplicates found
                        {
                            duplicateUIDCount = duplicateUIDCount +1;
                            if(duplicateUIDCount === 1 )
                            {
                                setLog(prev => [...prev,`The unique id column in the uploaded file contains duplicate entries. Please verify and correct them before proceeding.`]);
                            }                           
                        }

                        // check whether the unit name contain atleast 4 characters
                        if (UnitName.length < 4)
                        { 
                            setLog(prev => [...prev,`The unit name "${UnitName}" is too short. It should be at least 4 characters long.`]);
                        }

                        // check whether unit type and vehicle type are valid or not
                        function getKeyByValue(object, value)
                        {
                            return Object.keys(object).find(key => object[key] === value);
                        }  

                        if(VehicleType !== undefined)
                        {
                            //console.log(VehicleType);
                            if(VehicleType === "Other")
                            {
                                uniType = "other_vehicle";
                            }
                            else
                            {
                                uniType = getKeyByValue(unitTypes,VehicleType);
                                //console.log(uniType);
                            }                                    
                            if(uniType === undefined)
                            { 
                                setLog(prev => [...prev,`The vehicle type "${VehicleType}" for the unit "${UnitName}" is not recognized. Please check the vehicle type.`]);
                            }
                        }

                        /*
                        if((UnitType !== undefined) &&(VehicleType !== undefined))
                        {
                            if(VehicleType === "Other")
                            {
                                uniType = "other_vehicle";
                            }
                            else
                            {
                                uniType = getKeyByValue(unitTypes,VehicleType);
                                //console.log(uniType);
                            }                               
                            if (uniType === undefined)
                            { 
                                setLog(prev => [...prev,`"${VehicleType}" is not a valid Vehicle Type. Therefore, "${VehicleType}" is not added for the Unit "${UnitName}"`]);
                            }                           
                        }
                        if(UnitType && UnitType === "Asset")
                        {
                            setLog(prev => [...prev,`Unit Type "${UnitType}" is not added for the Unit "${UnitName}"`]);
                        }
                        if((UnitType !== undefined) &&(VehicleType === undefined))
                        {
                            setLog(prev => [...prev,`Unit Type "${UnitType}" is not added for the Unit "${UnitName}"`]);
                        }
                        */

                        // check whether device type is valid or not
                        try
                        {
                            //console.log(DeviceType);
                            var params={"filterType":"name", "filterValue":[DeviceType], "includeType":true,"ignoreRename":true} 
                            var unitC= await asyncRemoteCall("core/get_hw_types",params); //getting all hardware types and filtering
                            //console.log(unitC);
                            if(unitC.length> 0)
                            {
                                deviceid = unitC[0].id;
                                //console.log(deviceid);
                            }
                            else
                            {
                                deviceid = undefined;
                                //console.log(deviceid);
                                setLog(prev => [...prev,`The device type "${DeviceType}" for the unit "${UnitName}" is not supported. Please check the device type.`]);
                            }
                        }
                        catch(error)
                        {
                            //console.log("Failed to get device type ", error); 
                            setLog(prev => [...prev,`The device type "${DeviceType}" could not be retrieved. Please check the device type.`]);  
                        }

                        // Unit Groups name validation
                        if (UnitGroup1 !== undefined)
                        {
                            if(UnitGroup1.length <4)
                            {
                                setLog(prev => [...prev,`The unit group name "${UnitGroup1}" is too short. It should be at least 4 characters long.`]);
                            }
                        }
                        if (UnitGroup2 !== undefined)
                        {
                            if(UnitGroup2.length <4)
                            {
                                setLog(prev => [...prev,`The unit group name "${UnitGroup2}" is too short. It should be at least 4 characters long.`]);
                            }
                        }
                        if (UnitGroup3 !== undefined)
                        {
                            if(UnitGroup3.length <4)
                            {
                                setLog(prev => [...prev,`The unit group name "${UnitGroup3}" is too short. It should be at least 4 characters long.`]);
                            }
                        }
                        
                        //Phone number validation
                        if(Phone !== undefined)
                        {
                            phoneStr = Phone.toString(); // converting number to string to perform string operations
                            //appending + sign if it does not have that
                            if(phoneStr.includes("+") === false)
                            {
                                phNumber = "+" + phoneStr;   
                                //console.log(phNumber);
                            }
                            else
                            {
                                phNumber = phoneStr;
                                //console.log(phNumber);
                            }
                            if(phNumber.length < 8)
                            {
                                setLog(prev => [...prev,`The phone number for the unit "${UnitName}" appears to be too short. It should contain more than 8 digits.`]);                       
                            }
                        } 
                        if(Phone === undefined)     
                        {
                            phNumber = undefined;
                            //console.log(phNumber);
                        }
                                               
                        function hasDuplicatePhone(arr)  // check whether file contains duplicate uphone numbers
                        { 
                            const phoneCounts = {};
                            for (let j = 0; j < arr.length; j++)
                            {
                                const p = arr[j]["Phone Number"];
                                if (phoneCounts[p] && p !== undefined)
                                {   
                                    return true;
                                } 
                                else
                                { 
                                    phoneCounts[p] = 1;
                                }
                                }
                            return false; 
                        }
                        
                        if(hasDuplicatePhone(excelData))
                        {
                            duplicatePhoneCount = duplicatePhoneCount +1;
                            if(duplicatePhoneCount === 1 )
                            {
                                setLog(prev => [...prev,`Duplicate entries found in the phone number column of the file. Please verify and correct them before creating units.`]);
                            }
                                
                        }
                    
                        //checking vin
                        if(VIN !== undefined) 
                        {                
                            try
                            {
                                var unitB= await asyncRemoteCall("unit/get_vin_info",{"vin":VIN});
                                if(unitB.vin_lookup_result.hasOwnProperty('error'))
                                {
                                    setLog(prev => [...prev,`The VIN number "${VIN}" for the unit "${UnitName}" appears to be incorrect. Please verify.`]);                             
                                }                         
                            }
                            catch(error)
                            {
                                //console.log("failed to check whether VIN is valid or not");
                                setLog(prev => [...prev,`Unable to validate the VIN "${VIN}". Please check the VIN.`]);                           
                            }
                        }  

                        var uniqueUnit;
                        await checkUniqueUnits();

                        async function checkUniqueUnits()
                        {
                            // check whether unit exists or not, using uniqueid
                            try 
                            {                              
                                var unitD = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_unit","propName":"sys_unique_id","propValueMask":UniqueID,"sortType":"sys_unique_id",
                                                                        "propType":"property","or_logic":0},"force":0,"flags":1,"from":0,"to":0});
                                if (unitD.totalItemsCount > 0)
                                {  
                                    setLog(prev => [...prev,`The unit "${UnitName}" cannot be created because a unit with the same unique id "${UniqueID}" already exists.`]); 
                                    uniqueUnit = false;       
                                }
                                else
                                {
                                    uniqueUnit = true;
                                }

                                }    
                            catch(error)
                            {
                                //console.log("failed to check uniqueness of units");
                                setLog(prev => [...prev,`An error occurred while verifying the uniqueness of the unit "${UnitName}".`]);
                            }
                        }
                    
                        // Only creating units when all validations are passed
                        if ( (UnitName.length >= 4) && (UniqueID !== undefined) && (DeviceType !== undefined) && (uniqueUnit === true) &&
                        ((customName1 && customValue1) || (!customName1 && !customValue1)) && ((customName2 && customValue2) || (!customName2 && !customValue2)) && 
                        ((customName3 && customValue3) || (!customName3 && !customValue3)) && ((customName4 && customValue4) || (!customName4 && !customValue4)) &&
                        ((customName5 && customValue5) || (!customName5 && !customValue5)) && 
                        (!UnitType || !VehicleType || uniType !== undefined) &&
                        (!VIN || VIN) && 
                        (deviceid !== undefined) && 
                        (!UnitGroup1 || UnitGroup1) && (!UnitGroup2 || UnitGroup2) && (!UnitGroup3 || UnitGroup3) &&
                        ((!Phone) || (phNumber.length >= 8)) && 
                        !(hasDuplicateUID(excelData)) && !(hasDuplicatePhone(excelData)))
                        {
                            validationSuccessful = true;
                            //console.log(validationSuccessful);
                            if(validationSuccessful === true)
                            {
                                    var createdUnitGroups = [];
                                    //setIsLoading(true);
                                    //console.log(deviceid);
                                    //console.log(validationSuccessful);
                                    await createUnits(uniType,deviceid, phNumber,unitsCreatedCount,createdUnitGroups);
                            }
                        }           
                    } // end of if condition
                } // end of validate function
                            
                // if(validationSuccessful === true)
                // {
                //     var createdUnitGroups = [];
                //     //setIsLoading(true);
                //     //console.log(deviceid);
                //     console.log(validationSuccessful);
                //     await createUnits(uniType,deviceid, phNumber,unitsCreatedCount,createdUnitGroups);
                // }
                      
                async function createUnits(uniType,deviceid, phNumber,unitsCreatedCount,createdUnitGroups)
                {
                    try
                    {
                        console.log(DeviceType);
                        if((DeviceType !== undefined))
                        {
                            var unitD = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_unit","propName":"sys_unique_id","propValueMask":UniqueID,"sortType":"sys_unique_id",
                                                                "propType":"property","or_logic":0},"force":0,"flags":1,"from":0,"to":0});
                            //console.log(unitD);
                            //console.log(DeviceType);
                            if (unitD.totalItemsCount === 0)
                            {                             
                                var id = props.selectedUserId;
                                //var hwTypeId = "23365656";  // value to be overridden when passing actual hwtypeid
                                var dataflag =1;
                                // Creating Units                 
                                var unit = await asyncRemoteCall("core/create_unit",{"creatorId":id, "name":UnitName, "hwTypeId":deviceid, "dataFlags":dataflag}); 
                                //console.log(unit);                       
                                var newUnitId = unit.item.id; // extract unit id from console output
                                if(newUnitId !== undefined)
                                {
                                    unitsCreatedCount = unitsCreatedCount+1;
                                    //console.log(unitsCreatedCount);
                                    setSuccess(prev => [...prev,`Unit "${UnitName}" created`]);
                                    setUnitNames(prev => [...prev,UnitName]);
                                    // calling other functions to add parameters
                                    await addProfileFileds(newUnitId);
                                    await addUnitTypeAndVehicleType(newUnitId,uniType);
                                    await addVIN(newUnitId);
                                    await addPhone(newUnitId, phNumber);
                                    await addCustomFields(newUnitId);
                                    await AddtoUnitGroups(newUnitId,createdUnitGroups); 
                                    await addDeviceTypeAndUniqueID(newUnitId,deviceid,createdUnitGroups);
                                }           
                            }
                        }
                        else
                        {
                            setLog(prev => [...prev,`Creation of unit "${UnitName}" failed due to an invalid device type. Please check the device type.`]);
                        }                                
                    }  
                    catch(error)
                    {
                        setLog(prev => [...prev,`An error occurred while attempting to create the unit "${UnitName}" and its associated parameters.`]);
                    }                                     
                }

                // add license plate
                async function addProfileFileds(newUnitId) 
                {
                    try
                    {
                        if(LicensePlate)
                        {
                            var unit = await asyncRemoteCall("item/update_profile_field",{"itemId": newUnitId,"n":"registration_plate", "v":LicensePlate});
                            if(unit.length > 0)
                            {
                                setSuccess(prev => [...prev,`Successfully added license plate "${LicensePlate}" to the unit "${UnitName}".`]);
                                setPlate(prev => [...prev,LicensePlate]);
                            }
                            else
                            {
                                setLog(prev => [...prev,`Failed to add license plate "${LicensePlate}" to the unit "${UnitName}".`]);
                            }
                        }
                        else
                        {
                            setPlate(prev => [...prev," - "]);
                        }
                    }  
                    catch(error)
                    {
                        setLog(prev => [...prev,`The system encountered an error while trying to add the license plate "${LicensePlate}" to the unit "${UnitName}".`]);                       
                    }   
                    
                }

                // update device type and unique id
                async function addUnitTypeAndVehicleType(newUnitId,uniType) 
                {
                    try
                    {
                        if(VehicleType !== undefined)
                        {
                            //console.log(uniType);
                            var unit = await asyncRemoteCall("item/update_profile_field",{"itemId": newUnitId,"n":"vehicle_class", "v":uniType});
                            if(unit.length > 0)
                            {
                                setSuccess(prev => [...prev,`Vehicle type "${VehicleType}" added successfully for the unit "${UnitName}"`]);
                                setUnittype(prev => [...prev,UnitType]);
                                setVehicletype(prev => [...prev,VehicleType]);
                            }
                            else
                            {
                                setLog(prev => [...prev,`Failed to add vehicle type "${VehicleType}" to the unit "${UnitName}".`]);
                            }
                        }
                        else
                        {
                            setUnittype(prev => [...prev," - "]);
                            setVehicletype(prev => [...prev," - "]);
                        }                 
                        
                    }  
                    catch(error)
                    {
                        setLog(prev => [...prev,`An error occurred while trying to add the vehicle type "${VehicleType}" to the unit "${UnitName}".`]);
                    }                      
                } 

                // add VIN number
                async function addVIN(newUnitId) 
                {
                    try
                    {
                        if(VIN)
                        {
                            var unit = await asyncRemoteCall("unit/get_vin_info",{"vin":VIN});
                            if(unit.vin_lookup_result.hasOwnProperty('error'))
                            {
                                setLog(prev => [...prev,`The VIN "${VIN}" for the unit "${UnitName}" is not valid. Please check the VIN.`]);
                            }
                            else
                            {
                                var unit1 = await asyncRemoteCall("item/update_profile_field",{"itemId": newUnitId, "n":"vin", "v":VIN}); // updating vin
                                if(unit1.length > 0)
                                {
                                    setSuccess(prev => [...prev,`The VIN "${VIN}" has been successfully associated with the unit "${UnitName}".`]);
                                    setVin(prev => [...prev,VIN]);
                                } 
                            }  
                        }
                        else
                        {
                            setVin(prev => [...prev," - "]);
                        }
                    }  
                    catch(error)
                    {
                        setLog(prev => [...prev,`An error occurred while trying to add the VIN "${VIN}" for the unit "${UnitName}".`]);                       
                    }  
                }

                // updating phone number
                async function addPhone(newUnitId, phNumber) 
                {               
                    try
                    {
                        //console.log(phNumber);
                        if(phNumber !== undefined && phNumber.length < 8)
                        {
                            setLog(prev => [...prev,`An error occurred while trying to add the phone number "${Phone}" for the unit "${UnitName}".`]); 
                        }
                        if(phNumber !== undefined && phNumber.length >= 8)
                        {
                            //console.log(phNumber);
                            //console.log(newUnitId);
                            var unit = await asyncRemoteCall("unit/update_phone",{"itemId": newUnitId, "phoneNumber":phNumber});
                            //console.log(unit);
                            if(unit.ph !== undefined)
                            {                          
                                setSuccess(prev => [...prev,`Phone number "${Phone}" added successfully for the unit "${UnitName}".`]);
                                setPhone(prev => [...prev,phNumber]);
                            } 
                            else
                            {                           
                                setLog(prev => [...prev,`Failed to add phone number "${Phone}" for the unit "${UnitName}"`]); 
                            }                        
                        }
                        else
                        {
                            setPhone(prev => [...prev," - "]);
                        } 
                    }  
                    catch(error)
                    {                   
                        setLog(prev => [...prev,`Failed to add phone no. "${Phone}" for the unit "${UnitName}"`]); 
                    }                                                    
                }
                   
                //adding custom fields
                async function addCustomFields(newUnitId)
                {               
                    if(customName1 != null && customValue1 != null)
                    {
                        customFields(customName1,customValue1,newUnitId)
                    }
                    if(customName2 !== null && customValue2 !== null)
                    {
                        customFields(customName2,customValue2,newUnitId)
                    }
                    if(customName3 !== null && customValue3 !== null)
                    {
                        customFields(customName3,customValue3,newUnitId)
                    }
                    if(customName4 !== null && customValue4 !==null)
                    {
                        customFields(customName4,customValue4,newUnitId)
                    }
                    if(customName5 !== null && customValue5 !== null)
                    {
                        customFields(customName5,customValue5,newUnitId)
                    }
                    if((customName1 === undefined && customValue1 !== undefined) || (customName2 === undefined && customValue2 !== undefined) ||
                    (customName3 === undefined && customValue3 !== undefined) ||(customName4 === undefined && customValue4 !== undefined) ||
                    (customName5 === undefined && customValue5 !== undefined))
                    {
                        setLog(prev => [...prev,`Unable to add custom fields for the unit "${UnitName}" due to missing custom name.`]);                                                  
                    }
                    //console.log(customName1);
                    //console.log(customValue1);
                    if((customName1 !== undefined && customValue1 === undefined) ||(customName2 !== undefined && customValue2 === undefined) ||
                    (customName3 !== undefined && customValue3 === undefined) || (customName4 !== undefined && customValue4 === undefined) ||
                    (customName5 !== undefined && customValue5 === undefined))
                    {
                        setLog(prev => [...prev,`Unable to add custom fields for the unit "${UnitName}" due to missing custom name.`]);                                                  
                    }
                                           
                    async function customFields(n, vl, newUnitId)
                    {
                        try
                        {
                            if((n&&vl) !== undefined)
                            {
                                var unit = await asyncRemoteCall("item/update_custom_field",{"itemId":newUnitId,"id":0,"callMode":"create","n":n,"v":vl});
                                if(unit.length > 0)
                                {
                                    setsuccessCustom(prev => [...prev,`Successfully added custom field "${n}" with value "${vl}" to the unit "${UnitName}"`]);       
                                }  
                                else
                                {
                                    setLog(prev => [...prev,`Failed to add custom field - name "${n}" with value "${vl}" for the unit "${UnitName}"`]);                                                  
                                }                                    
                            }
                        }
                        catch(error) 
                        {
                            setLog(prev => [...prev,`An error occurred while trying to add the custom field - name "${n}" with value "${vl}" for the unit "${UnitName}".`]);
                        } 
                    }                           
                }

                // adding units to unit groups  
                async function AddtoUnitGroups(newUnitId,createdUnitGroups)
                {                         
                    var existingUnitsIds = [];
                    
                    if(UnitGroup1 !== undefined) 
                    {
                        await addUG(UnitGroup1);
                    }  
                    if(UnitGroup2 !== undefined) 
                    {                       
                        await addUG(UnitGroup2);
                    }  
                    if(UnitGroup3 !== undefined) 
                    {
                        await addUG(UnitGroup3);
                    }  
               
                    async function addUG(group)
                    {
                        //console.log(group);
                        if(group.length <4)
                        {
                            setLog(prev => [...prev,`The unit group name "${group}" is too short. It should be at least 4 characters long.`]);
                        }
                        else
                        {            
                            try
                            {
                                var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_unit_group","propName":"sys_name","propValueMask":group,
                                                                "sortType":"sys_name","propType":"","or_logic":0},"force":0,"flags":1,"from":0,"to":0});                   
                                if(unit.items.length === 0)
                                {
                                    await createUnitGroup(group,newUnitId);
                                }
                                else
                                {
                                    var groupName = unit.items[0].nm;
                                    var groupId= unit.items[0].id;
                                    existingUnitsIds=(unit.items[0].u);
                                    existingUnitsIds.push(newUnitId);
                                    var unit2 = await asyncRemoteCall("unit_group/update_units",{"itemId":groupId, "units":existingUnitsIds});  
                                    //console.log(unit);     
                                    //console.log(unit.u.length);
                                    if(unit2.u.length > 0)
                                    {          
                                        setsuccessUnitGroups(prev => [...prev,`The unit "${UnitName}" has been successfully assigned to the unit group "${groupName}"`]);   
                                    }
                                }                                
                            }                     
                            catch(error) 
                            {
                                setLog(prev => [...prev,`An error occurred while trying to retrieve details for the unit group "${groupName}".`]);
                            }  
                        }                                            
                    }  
                                    
                    async function existingUnitGroup(groupName,groupId,existingUnitsIds)
                    {
                        try
                        {
                            var unit = await asyncRemoteCall("unit_group/update_units",{"itemId":groupId, "units":existingUnitsIds});   
                            //console.log(unit); 
                            //console.log(unit.u.length);
                            if(unit.u.length > 0)
                            {
                                setsuccessUnitGroups(prev => [...prev,`The unit "${UnitName}" has been successfully assigned to the unit group "${groupName}"`]);   
                                createdUnitGroups.push(groupId);
                                //console.log(createdUnitGroups)
                            }                                                                     
                        }
                        catch(error) 
                        {
                            setLog(prev => [...prev,`An error occurred while trying to add the unit "${UnitName}" to the existing unit group "${groupName}". `]);
                        }                      
                    }

                    async function createUnitGroup(group,newUnitId)
                    {
                        try
                        {
                            var groupCreatorId= props.selectedUserId;
                            var unit = await asyncRemoteCall("core/create_unit_group",{"creatorId":groupCreatorId, "name":group,"dataFlags":1});  
                            if (unit.hasOwnProperty('item')){
                                var groupId= unit.item.id;
                                var groupName= unit.item.nm;
                                var addUnitsIds = [];
                                addUnitsIds.push(newUnitId);
                                await existingUnitGroup(groupName,groupId,addUnitsIds);
                            }
                            else{
                                setLog(prev => [...prev,`Failed to create new unit group "${group}" for the unit "${UnitName}"`]);
                            }
                            
                        }
                        catch(error) 
                        {
                            setLog(prev => [...prev,`Failed to create unit group "${group}" for the unit "${UnitName}"`]);
                        }                      
                    }
                } // end of unit group function

                //update device type and unique id
                async function addDeviceTypeAndUniqueID(newUnitId,deviceid,createdUnitGroups)  
                {
                    try
                    {
                        if(deviceid && UniqueID )
                        {
                            var unit = await asyncRemoteCall("unit/update_device_type",{"itemId":newUnitId,"deviceTypeId":deviceid,"uniqueId":UniqueID});
                            console.log(unit);
                            if((unit.hw && unit.uid) !== undefined)
                            {
                                setSuccess(prev => [...prev,`Successfully added device type and unique id for the unit "${UnitName}"`]);
                                setDeviceid(prev => [...prev,DeviceType]);
                                setUid(prev => [...prev,UniqueID]);
                                var uidd = newUnitId;
                                var hname= DeviceType;            
                            } 
                            else{
                                setLog(prev => [...prev,`Failed to add Device Type "${DeviceType}" and Unique Id "${UniqueID} for the Unit "${UnitName}"`]);
                            } 
                            await findWlpWithUnitidAndHwName(uidd,hname,createdUnitGroups);                           
                        }
                        else
                        {
                            setDeviceid(prev => [...prev," - "]);
                            setUid(prev => [...prev," - "]);
                        }
                    }  
                    catch(error)
                    {
                        setLog(prev => [...prev,`An issue encountered while adding Device Type "${DeviceType}" and Unique Id "${UniqueID} for the Unit "${UnitName}". Check & verify all access rights and wlp file applied sections.`]);
                    } 
                }

                async function findWlpWithUnitidAndHwName(unitIdd,hwname,createdUnitGroups)
                {

                        var comp1 = "lvm";
                        //var comp2 = "var";
                        var comp3 = "astreon";
                        var companyname;
                        var loggedUserName= props.loggedUser;
                        var billPlan = props.billingPlan;
                        var measuring = props.measurement;     
                        if(loggedUserName.toLowerCase().includes(comp1.toLowerCase()))
                        {
                            companyname = "lvm";
                        }
                        // else if(loggedUserName.toLowerCase().includes(comp2.toLowerCase()))
                        // {
                        //     companyname = "var";
                        // }
                        else if(loggedUserName.toLowerCase().includes(comp3.toLowerCase()))
                        {
                            companyname = "astreon";
                        }

                        // Creating variables for billing plans so that if later the name is changed, we dont want to replace it everywhere
                        //BILLING PLANS
                        const lvmStd = "LVMTech Standard 365";
                        const lvmPro = "LVMTech Pro 365";
                        const lvmProPlus = "LVMTech Pro+ 365";
                        const gLvmStd = "Google LVMTech Standard 365";
                        const gLvmPro = "Google LVMTech Pro 365";
                        const gLvmProPlus = "Google LVMTech Pro+ 365";
                        const astStd = "Astreon Standard 365";
                        const astPro = "Astreon Pro 365";
                        const astProPlus = "Astreon Pro+ 365";
                        const gastStd = "Google Astreon Standard 365";
                        const gastPro = "Google Astreon Pro 365";
                        const gAstProPlus = "Google Astreon Pro+ 365";

                        // Measurement System
                        const M = "Metric";
                        const U = "U.S.";
                        const Imp = "Imperial";
                        const metGall = "Metric with gallons";

                        // Hardware names
                        const aL11 = "ATrack AL11";
                        const aX11 = "ATrack AX11";
                        const bewhere = "Bewhere Gateway";
                        const gnx6 = "GNX-6";
                        const pt40 = "PT40";
                        const queclinkGV600MG =  "Queclink GV600MG";
                        const starlink = "STaRLink AVL";
                        const streamaxX3H0402 = "Streamax X3-H0402";
                        const teltonicaFMM00A = "Teltonika FMM00A";
                        const topflyTLD2D = "Topflytech TLD2-D";
                        const topflyTLD2L = "Topflytech TLD2-L";
                        const topflyTLP1LF = "Topflytech TLP1-LF";
                        const topflyTLP1SF = "Topflytech TLP1-SF";
                        const topflyTLW212BL = "Topflytech TLW2-12BL";
                        const wherequbeOBD = "whereQube OBD";

                        const lvm = "lvm";
                        const astreon = "astreon";
                        const varCompany = "var";

                        // giving errors to harware names which does not have wlp files
                        const availableWLP = [aL11,aX11,bewhere,gnx6,pt40,queclinkGV600MG,starlink,streamaxX3H0402,teltonicaFMM00A,topflyTLD2D,topflyTLD2L,topflyTLP1LF,topflyTLP1SF,topflyTLW212BL,wherequbeOBD];
                        if (!(availableWLP.includes(hwname))) 
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware type "${hwname}" is not supported.`]);
                        } 
                        if((measuring === Imp) || (measuring === metGall))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with measurement system "${measuring}" is not supported.`]);
                        }
                        if((hwname === bewhere) && (billPlan === lvmPro || billPlan === lvmProPlus || billPlan ===gLvmPro || billPlan ===gLvmProPlus ||billPlan ===astPro || billPlan ===astProPlus || billPlan ===gastPro || billPlan ===gAstProPlus ))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}" and billing plan "${billPlan}" is not supported.`]);
                        }
                        if((hwname === gnx6) && (measuring === M)&& (billPlan === lvmStd || billPlan ===gLvmStd || billPlan ===astStd|| billPlan ===gastStd))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}", measurement system "${measuring}", and billing plan "${billPlan}" is not supported.`]);
                        }
                        if((hwname === gnx6) && (measuring === U))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}" and measurement system "${measuring}" is not supported.`]);
                        }
                        if((hwname === queclinkGV600MG) && (billPlan === lvmPro|| billPlan ===lvmProPlus || billPlan ===gLvmPro || billPlan ===gLvmProPlus ||billPlan ===astPro ||billPlan === astProPlus || billPlan ===gastPro ||billPlan ===gAstProPlus ))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}" and billing plan "${billPlan}" is not supported.`]);
                        }
                        if((hwname === starlink) && (measuring === U))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}" and measurement system "${measuring}" is not supported.`]);
                        }
                        if((hwname === topflyTLP1LF) && (billPlan === lvmPro  || billPlan ===lvmProPlus || billPlan ===gLvmPro ||billPlan === gLvmProPlus ||billPlan ===astPro || billPlan ===astProPlus || billPlan ===gastPro || billPlan ===gAstProPlus ))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}" and billing plan "${billPlan}" is not supported.`]);
                        }
                        if((hwname === topflyTLP1SF) && (billPlan === lvmPro || billPlan ===lvmProPlus ||billPlan === gLvmPro || billPlan ===gLvmProPlus||billPlan ===astPro || billPlan ===astProPlus ||billPlan === gastPro || billPlan ===gAstProPlus ))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}" and billing plan "${billPlan}" is not supported.`]);
                        }
                        if((hwname === teltonicaFMM00A) && (billPlan === lvmStd || billPlan ===gLvmStd || billPlan ===astStd|| billPlan ===gastStd))
                        {
                            setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for units with hardware "${hwname}", measurement system "${measuring}", and billing plan "${billPlan}" is not supported.`]);
                        }
                        
                        const conditions = [
                            {
                                company: [lvm,astreon],
                                hname: aL11,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: AL11MetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: aL11,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: AL11MetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: aL11,
                                meas: U,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: AL11USPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: aL11,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: AL11USStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: aX11,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: AX11MetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: aX11,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: AX11MetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: aX11,
                                meas: U,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: AX11USPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: aX11,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: AX11USStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: bewhere,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: BewhereMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: bewhere,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: BewhereUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: gnx6,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: GNXMetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: pt40,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: PT40MetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: pt40,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: PT40MetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: pt40,
                                meas: U,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: PT40USPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: pt40,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: PT40USStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: queclinkGV600MG,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: Quec600MetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: queclinkGV600MG,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: Quec600USStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: starlink,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: StarMetricPro,
                            },
                            {
                                company:[lvm,astreon],
                                hname: starlink,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: StarMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: streamaxX3H0402,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: StreamMetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: streamaxX3H0402,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: StreamMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: streamaxX3H0402,
                                meas: U,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: StreamUSPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: streamaxX3H0402,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: StreamUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2D,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: TopDMetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2D,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopDMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2D,
                                meas: U,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: TopDUSPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2D,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopDUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2L,
                                meas: M,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: TopLMetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2L,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopLMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2L,
                                meas: U,
                                bills: [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: TopLUSPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLD2L,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopLUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLP1LF,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopLFMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLP1LF,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopLFUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLP1SF,
                                meas: M,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopSFMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLP1SF,
                                meas: U,
                                bills: [lvmStd, gLvmStd, astStd, gastStd],
                                f: TopSFUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLW212BL,
                                meas: M,
                                bills:  [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: Top12BLMetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLW212BL,
                                meas: M,
                                bills:  [lvmStd, gLvmStd, astStd, gastStd],
                                f: Top12BLMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLW212BL,
                                meas: U,
                                bills:  [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: Top12BLUSPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: topflyTLW212BL,
                                meas: U,
                                bills:  [lvmStd, gLvmStd, astStd, gastStd],
                                f: Top12BLUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: wherequbeOBD,
                                meas: M,
                                bills:  [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: WhereMetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: wherequbeOBD,
                                meas: M,
                                bills:  [lvmStd, gLvmStd, astStd, gastStd],
                                f: WhereMetricStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: wherequbeOBD,
                                meas: U,
                                bills:  [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: WhereUSPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: wherequbeOBD,
                                meas: U,
                                bills:  [lvmStd, gLvmStd, astStd, gastStd],
                                f: WhereUSStd,
                            },
                            {
                                company: [lvm,astreon],
                                hname: teltonicaFMM00A,
                                meas: M,
                                bills:  [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: TeltMetricPro,
                            },
                            {
                                company: [lvm,astreon],
                                hname: teltonicaFMM00A,
                                meas: U,
                                bills:  [lvmPro, lvmProPlus, gLvmPro, gLvmProPlus, astPro, astProPlus, gastPro, gAstProPlus],
                                f: TeltUSPro,
                            },
                            {
                                company: varCompany,
                                hname: aL11,
                                meas: M,                            
                                f: AL11MetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: aL11,
                                meas: U,                            
                                f: AL11USVAR,
                            },
                            {
                                company: varCompany,
                                hname: aX11,
                                meas: M,                            
                                f: AX11MetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: aX11,
                                meas: U,                            
                                f: AX11USVAR,
                            },
                            {
                                company: varCompany,
                                hname: bewhere,
                                meas: M,                            
                                f: BewhereMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: bewhere,
                                meas: U,                            
                                f: BewhereUSVAR,
                            },
                            {
                                company: varCompany,
                                hname: pt40,
                                meas: M,                            
                                f: PT40MetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: pt40,
                                meas: U,                            
                                f: PT40USVAR,
                            },
                            {
                                company: varCompany,
                                hname: queclinkGV600MG,
                                meas: M,                            
                                f: Quec600MetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: queclinkGV600MG,
                                meas: U,                            
                                f: Quec600USVAR,
                            },
                            {
                                company: varCompany,
                                hname: streamaxX3H0402,
                                meas: M,                            
                                f: StreamMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: streamaxX3H0402,
                                meas: U,                            
                                f: StreamUSVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLD2D,
                                meas: M,                            
                                f: TopDMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLD2D,
                                meas: U,                            
                                f: TopDUSVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLD2L,
                                meas: M,                            
                                f: TopLMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLD2L,
                                meas: U,                            
                                f: TopLUSVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLP1LF,
                                meas: M,                            
                                f: TopLFMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLP1LF,
                                meas: U,                            
                                f: TopLFUSVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLP1SF,
                                meas: M,                            
                                f: TopSFMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLP1SF,
                                meas: U,                            
                                f: TopSFUSVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLW212BL,
                                meas: M,                            
                                f: Top12BLMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: topflyTLW212BL,
                                meas: U,                            
                                f: Top12BLUSVAR,
                            },
                            {
                                company: varCompany,
                                hname: wherequbeOBD,
                                meas: M,                            
                                f: WhereMetricVAR,
                            },
                            {
                                company: varCompany,
                                hname: wherequbeOBD,
                                meas: U,                            
                                f: WhereUSVAR,
                            },
                        ];

                        //find file name
                    function findFileName(companyname, hwname, measuring, billPlan) {
                        const matchingRecord = conditions.find((condition) => {
                            return (
                                condition.company.includes(companyname) &&
                                condition.hname === hwname &&
                                condition.meas === measuring &&
                                condition.bills.includes(billPlan)
                            );
                        });                   
                        return matchingRecord ? matchingRecord.f : undefined;
                    }

                    const filenm = findFileName(companyname, hwname, measuring, billPlan);
                    console.log(filenm);      
                    if(filenm !== undefined)
                    {
                        await addSensors(filenm,unitIdd);
                        await addHardwareConfig(filenm,unitIdd,hwname);
                        await addCounters(filenm,unitIdd);
                        await addAdvancedProperties(filenm,unitIdd);
                        await addTrip(filenm,unitIdd);
                        await addEco(filenm,unitIdd);
                    }
                    else
                    {
                        setLog(prev => [...prev,`Adding Sensors, Trip detectors, Hardware configurations, Mileage and Engine hours counters, Eco driving and Advanced properties for the unit "${UnitName}" is not supported.`]);
                    }
                    
                    async function addSensors(filenm,key)
                    {                    
                        var sensors= filenm.sensors;
                        console.log(filenm, sensors);
                        if(sensors !== undefined){
                            try
                            {                           
                                for(var j=0; j<sensors.length; j++)
                                {  
                                    let unit;                                
                                    try
                                    {
                                        if(sensors[j].vs === 0)
                                        {
                                            var unitID = key;
                                            var id = 0;
                                            var mode= "create";                   
                                            var unlink=1;                   
                                            var name = sensors[j].n;                       
                                            var type = sensors[j].t;                       
                                            var desc = sensors[j].d;                       
                                            var metric = sensors[j].m;                       
                                            var para = sensors[j].p;                   
                                            var flag = sensors[j].f;                       
                                            var config = sensors[j].c;                   
                                            var validtype = sensors[j].vt;                   
                                            var validsens = sensors[j].vs;                       
                                            var tabl = sensors[j].tbl;
                                                            
                                            var params = {"itemId":unitID,"id":id,"callMode":mode, "unlink":unlink,
                                                    "n":name,"t":type,"d":desc,"m":metric,"p":para,"f":flag,
                                                    "c":config, "vt":validtype,"vs":validsens,"tbl":tabl };
                                        
                                            unit = await asyncRemoteCall("unit/update_sensor",params);
                                            console.log(unit);
                                            if(unit[0] !== undefined)
                                            {
                                                setSuccess(prev => [...prev,`Successfully added sensor "${unit[1].n}" to the unit "${UnitName}"`]);
                                            }
                                            else{
                                                setLog(prev => [...prev,`Failed to add sensor "${unit[1].n}" for the unit "${UnitName}"`]);
                                            }
                                            
                                        } 
                                    }
                                    catch(error) 
                                    {
                                        setLog(prev => [...prev,`An error occurred while trying to add the sensor "${unit[1].n}" to the unit "${UnitName}".`]);
                                    }                                                               
                                }

                                // after creation of all sensors, update the vs parameter since it refers to on eof the sensor
                                // Define the function outside of the loop
                                const findSensorName = item => item.n === sensorName;
                                for(let k=0; k<sensors.length; k++)
                                {
                                    if(sensors[k].vs !== 0)
                                    {
                                        let unit3;
                                        try
                                        {
                                            var sensorName = "";
                                            var sensr = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_unit","propName":"sys_id","propValueMask":key,"sortType":"sys_id",
                                                    "propType":"property","or_logic":0},"force":0,"flags":4097,"from":0,"to":0});
                                            var searchObject = sensr.items[0].sens;
                                            // sensor id from sensors list
                                            var foundSensor = filenm.sensors.find(sensor => sensor.id === sensors[k].vs);
                                            if (foundSensor) 
                                            {
                                                sensorName = foundSensor.n;
                                            }
                                            if(sensorName !== ""){                               
                                                const sName = Object.values(searchObject).find(findSensorName);
                                                var sId = sName ? sName.id : 0;
                                            }  
                                            var unitId = key;
                                            var idd = 0;
                                            var mde= "create";                   
                                            var ulink=1;                   
                                            var nme = sensors[k].n;                       
                                            var typ = sensors[k].t;                       
                                            var des = sensors[k].d;                       
                                            var mtric = sensors[k].m;                       
                                            var par = sensors[k].p;                   
                                            var flg = sensors[k].f;                       
                                            var cnfig = sensors[k].c;                   
                                            var validtyp = sensors[k].vt;                   
                                            //var valdsens = sensors[k].vs;                       
                                            var tbl = sensors[k].tbl;
                                                            
                                            var param = {"itemId":unitId,"id":idd,"callMode":mde, "unlink":ulink,
                                                        "n":nme,"t":typ,"d":des,"m":mtric,"p":par,"f":flg,
                                                        "c":cnfig, "vt":validtyp,"vs":sId,"tbl":tbl };
                                            
                                            unit3 = await asyncRemoteCall("unit/update_sensor",param);
                                            console.log(unit3);
                                            if(unit3[0] !== undefined)
                                            {
                                                setSuccessSensors(prev => [...prev,`Sensor "${unit3[1].n}" added successfully for "${UnitName}"`]);
                                            }
                                            else{
                                                setLog(prev => [...prev,`Failed to add sensor "${unit3[1].n}" for the unit "${UnitName}".`]);
                                            }
                                            
                                        } 
                                        catch(error) 
                                        {
                                            setLog(prev => [...prev,`Failed to add sensor "${unit3[1].n}" for the unit "${UnitName}"`]);
                                        } 
                                    }
                                }
                            }
                            catch(error)
                            {
                                setLog(prev => [...prev,`Failed to add sensors for the unit "${UnitName}"`]);
                            } 
                        }                   
                    }

                    async function addHardwareConfig(filenm,key,hwname)
                    {
                        var hconfig = filenm.hwConfig;
                        console.log(filenm, hconfig);
                        if(hconfig !== undefined)
                        {
                            if(hconfig.params.length > 0)
                            {
                                var items = hconfig.params;
                                const updatedItems = items.map((item) => {
                                    return {
                                        name: item.name,
                                        type: item.type,
                                        value: item.value,
                                        data:"",
                                        reset:0
                                    }; 
                                });

                                var hwid;
                                try
                                {
                                    var params={"filterType":"name", "filterValue":[hwname], "includeType":true,"ignoreRename":true} 
                                    var unit= await asyncRemoteCall("core/get_hw_types",params); //getting all hardware types and filtering
                                    if(unit.length> 0)
                                    {
                                        hwid = unit[0].id;
                                    }
                                    else
                                    {
                                        setLog(prev => [...prev,`Hardware name "${hwname}" is not valid.`]);
                                    }
                                }
                                catch(error)
                                {
                                    setLog(prev => [...prev,`Failed to check whether the hardware name "${hwname}" is valid or not`]);   
                                }

                                try
                                {
                                    var param = {"itemId":key,"hwId":hwid,"params_data":{"reset_all":0,"params":updatedItems,"full_data":0},"action":"set"}
                                    var unit4 = await asyncRemoteCall("unit/update_hw_params",param);
                                    if(unit4.length === undefined)
                                    {
                                        setSuccessHwConfig(prev => [...prev,`Hardware configurations added successfully for "${UnitName}"`]);
                                    }
                                    else
                                    {
                                        setLog(prev => [...prev,`Failed to add hardware configurations for the unit "${UnitName}".`]);
                                    }
                                    
                                } 
                                catch(error) 
                                {
                                    setLog(prev => [...prev,`Failed to add hardware configurations for the unit "${UnitName}"`]); 
                                }
                            }
                        }
                    }

                    async function addCounters(filenm,key)
                    {
                        var count = filenm.counters;
                        console.log(filenm, count);
                        if (count !== undefined)
                        {                         
                            var unitID = key;
                            var mileageC = count.cnm;
                            var enginehours = count.cneh;
                            var calcflag = count.cfl;

                            // mileage
                            try
                            {
                                var unit = await asyncRemoteCall("unit/update_mileage_counter",{"itemId":unitID,
                                "newValue":mileageC});
                                if(unit.cnm !== undefined){
                                    setSuccessMileage(prev => [...prev,`Mileage counter added successfully for the unit "${UnitName}"`]);
                                } 
                                else{
                                    setLog(prev => [...prev,`Failed to add mileage counter for the unit "${UnitName}".`]);
                                }                                               
                            } 
                            catch(error) 
                            {
                                setLog(prev => [...prev,`Failed to add mileage counter for the unit "${UnitName}"`]);
                            }

                            // engine hours
                            try
                            {
                                var unit5 = await asyncRemoteCall("unit/update_eh_counter",{"itemId":unitID,
                                "newValue":enginehours});
                                if(unit5.cneh !== undefined)
                                {
                                    setSuccessEngine(prev => [...prev,`Engine hours counter added successfully for the unit "${UnitName}"`]);
                                }
                                else{
                                    setLog(prev => [...prev,`Failed to add engine hours counter for the unit "${UnitName}".`]);
                                }
                                
                            } 
                            catch(error) 
                            {
                                setLog(prev => [...prev,`Failed to add engine hours counter for the unit "${UnitName}"`]);
                            }

                            try
                            {
                                var unit6 = await asyncRemoteCall("unit/update_calc_flags",{"itemId":unitID,
                                "newValue":calcflag});
                                if(unit6.hasOwnProperty("cfl"))
                                {
                                    setSuccessEngine(prev => [...prev,`Calculation flags added successfully for the unit "${UnitName}"`]);
                                }
                            } 
                            catch(error) 
                            {
                                setLog(prev => [...prev,`An error occurred while trying to add the checkmark for the "auto" parameter in the counters section of the unit "${UnitName}".`]);
                            }
                        }

                    }

                    async function addAdvancedProperties(filenm,key)
                    {    
                        var adv = filenm.advProps;
                        console.log(filenm, adv);
                        if(adv !== undefined)
                        {
                            var unitID = key;
                            var sensorName = "";
                            var sensr = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_unit","propName":"sys_id","propValueMask":unitID,"sortType":"sys_id",
                                        "propType":"property","or_logic":0},"force":0,"flags":4097,"from":0,"to":0});
                            console.log(sensr);
                            var searchObject = sensr.items[0].sens;
                    
                            // these 4 uses sensors
                            var monitSensId = adv.monitoring_sensor_id;
                            console.log(monitSensId);
                            if(monitSensId !== "" && monitSensId !== "0")
                            {
                                monitSensId = await findSensorId(monitSensId);
                                console.log(monitSensId);
                            }
                            var motStaSensId = adv.motion_state_sensor_id;
                            console.log(motStaSensId);
                            if(motStaSensId !== "" && motStaSensId !== "0")
                            {
                                motStaSensId = await findSensorId(motStaSensId);
                                console.log(motStaSensId);
                            }
                            var monitBattId = adv.monitoring_battery_id;
                            console.log(monitBattId);
                            if(monitBattId !== "" && monitBattId !== "0")
                            {
                                monitBattId = await findSensorId(monitBattId);
                                console.log(monitBattId);
                            }
                            var senscolorid = adv.sensors_colors_id;
                            if(senscolorid !== "" && senscolorid !== "0")
                            {
                                senscolorid = await findSensorId(senscolorid);
                            }

                            async function findSensorId(sensId){
                                console.log(sensId);
                                console.log(filenm.sensors);
                                var foundSensor = filenm.sensors.find(sensor => Number(sensor.id) === Number(sensId));
                                console.log(foundSensor);
                                if (foundSensor !== undefined) 
                                {
                                    sensorName = foundSensor.n;
                                    console.log(sensorName);
                                    if(sensorName !== "" || sensorName !== undefined){                               
                                        const sName = Object.values(searchObject).find(item => item.n === sensorName);;
                                        var Id = sName ? sName.id : "";
                                    } 
                                }                           
                                return Id;
                            }

                            //var monitSens = adv.monitoring_sensor;
                            var useSensColo = adv.use_sensor_color;                                      
                            var trkSens = adv.track_sensor;
                            var sensColor = adv.sensors_colors;
                            var trackSolid  =adv.track_solid;
                            var solidColor =adv.solid_colors;
                            var trackSpeed = adv.track_speed;
                            var speedColors = adv.speed_colors;
                            var lablColor = adv.label_color;
                            var tripColor = adv.trip_colors;
                            var enable = adv.msgFilter.enabled;
                            var skipinl = adv.msgFilter.skipInvalid;
                            var minSat = adv.msgFilter.minSats;
                            var maxhdop = adv.msgFilter.maxHdop;
                            var maxspeed = adv.msgFilter.maxSpeed;

                            // Update settings of messages filtration
                            try
                            {
                                var params = {"itemId":unitID,
                                "enabled":enable,
                                "skipInvalid":skipinl,
                                "minSats":minSat,
                                "maxHdop":maxhdop,
                                "maxSpeed":maxspeed}

                                var unit = await asyncRemoteCall("unit/update_messages_filter",params);
                                if (Object.keys(unit).length === 0) {
                                    var filtartion = true;
                                }
                                else{
                                    setLog(prev => [...prev,`Failed to add message filtration parameters for the unit "${UnitName}"`]);
                                }                           
                            } 
                            catch(error) 
                            {
                                setLog(prev => [...prev,`Failed to add message filtration parameters of advanced properties for the unit "${UnitName}"`]);
                            }

                            try
                            {
                                var param = {"params":[{"svc":"item/update_custom_property","params":{"itemId":unitID,
                                "name":"use_sensor_color","value":useSensColo}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"monitoring_sensor_id",
                                "value":monitSensId}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"motion_state_sensor_id",
                                "value":motStaSensId}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"track_sensor",
                                "value":trkSens}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"track_solid",
                                "value":trackSolid}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"track_speed",
                                "value":trackSpeed}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"sensors_colors_id",
                                "value":senscolorid}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"solid_colors",
                                "value":solidColor}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"sensors_colors",
                                "value":sensColor}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"speed_colors",
                                "value":speedColors}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"monitoring_battery_id",
                                "value":monitBattId}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"label_color",
                                "value":lablColor}},
                                {"svc":"item/update_custom_property","params":{"itemId":unitID,"name":"trip_colors",
                                "value":tripColor}}],"flags":0}

                                var unit5 = await asyncRemoteCall("core/batch",param);
                                let errorObj = unit5.find(obj => obj.hasOwnProperty('error'));
                                if (errorObj) {
                                    setLog(prev => [...prev,`Failed to add custom properties of advanced properties for the unit "${UnitName}"`]);
                                }
                                else{
                                    var customP =true;
                                }                                                  
                            }
                            catch(error)
                            {
                                setLog(prev => [...prev,`Failed to add custom properties of advanced properties of the unit "${UnitName}"`]);   
                            }
                        


                            // Update parameters used in reports
                            var report = filenm.reportProps;
                            console.log(filenm, report);
                            
                            var speedLt = report.speedLimit;
                            var maxMsgInterval = report.maxMessagesInterval;
                            var dailyEngine = report.dailyEngineHoursRate;
                            var urbanMax = report.urbanMaxSpeed;
                            console.log(urbanMax);
                            var mileagecoeff = report.mileageCoefficient;
                            console.log(mileagecoeff);
                            var speedingTole = report.speedingTolerance;
                            var speedmindur = report.speedingMinDuration;
                            var speedmode = report.speedingMode;
                            var fuelrate = report.fuelRateCoefficient;
                            try{
                                var parms = {"itemId":unitID,
                                    "params":{
                                        "speedLimit":speedLt,
                                        "maxMessagesInterval":maxMsgInterval,
                                        "dailyEngineHoursRate":dailyEngine,
                                        "urbanMaxSpeed":urbanMax,
                                        "mileageCoefficient":mileagecoeff,
                                        "speedingTolerance":speedingTole,
                                        "speedingMinDuration":speedmindur,
                                        "speedingMode":speedmode,
                                        "fuelRateCoefficient":fuelrate
                                    }}

                                var unit7 = await asyncRemoteCall("unit/update_report_settings",parms);
                                //console.log(unit7);
                                if (Object.keys(unit7).length === 0) {
                                    var reports =true;
                                }
                                
                            } 
                            catch(error) 
                            {
                                setLog(prev => [...prev,`Failed to add parameters used in report of advanced properties of the unit "${UnitName}"`]);   
                            }


                            if((filtartion === true) && (customP === true) && (reports=== true))
                            {
                                setSuccessAdvanced(prev => [...prev,`Advanced properties added successfully for the unit"${UnitName}"`]);
                            }
                            else
                            {
                                setLog(prev => [...prev,`Failed to add advanced properties for the unit "${UnitName}"`]);
                            }
                        }
                    }

                    // Trip detection settings
                    async function addTrip(filenm,key)
                    {
                        var unitID = key;
                        var trips= filenm.trip;

                        if(trips !== undefined){
                            console.log(filenm, trips);
                            var gps= trips.gpsCorrection;
                            var distance= trips.maxMessagesDistance;           
                            var speed= trips.minMovingSpeed;
                            var sat= trips.minSat;
                            var stay= trips.minStayTime;
                            var tripdist= trips.minTripDistance;
                            var triptime= trips.minTripTime;
                            var triptyp= trips.type;

                            var params = {"itemId":unitID,"type":triptyp,"gpsCorrection":gps, "minSat":sat,
                            "minMovingSpeed":speed,"minStayTime":stay,"maxMessagesDistance":distance,
                            "minTripTime":triptime,
                            "minTripDistance":tripdist };
                                
                            try
                            {
                                var unit = await asyncRemoteCall("unit/update_trip_detector",params);
                                //console.log(unit);
                                if(unit.length ===undefined){
                                    setSuccessTrip(prev => [...prev,`Trip detectors added successfully for the unit "${UnitName}"`]);
                                }  
                                else{
                                    setLog(prev => [...prev,`Failed to add trip detectors for the unit "${UnitName}".`]);
                                }                     
                            } 
                            catch(error) 
                            {
                                setLog(prev => [...prev,`Failed to add trip detectors for the unit "${UnitName}"`]);
                            }
                        } 
                    }

                    // Eco driving
                    async function addEco(filenm,key)
                    {
                        var unitID = key;
                        var driving= filenm.driving;
                        if (driving !== undefined)
                        {
                            console.log(filenm, driving);
                            var accelmode = driving.global.accel_mode;

                            if(driving.acceleration !== undefined)
                            {
                                var s = driving.acceleration;
                                var accelerationArray= [];
                                for(var a=0; a<s.length; a++)
                                {
                                    var flag = s[a].flags;
                                    var minv= s[a].min_value;
                                    var maxv= s[a].max_value;
                                    var mins= s[a].min_speed;
                                    var maxs= s[a].max_speed;
                                    var mind= s[a].min_duration;
                                    var maxd= s[a].max_duration;
                                    var validid= s[a].validator_id;
                                    var nm= s[a].name;
                                    var penalities =  s[a].penalties;
                                    var value = 
                                                {
                                                    "flags":flag,
                                                    "min_value":minv,
                                                    "max_value":maxv,
                                                    "min_speed":mins,
                                                    "max_speed":maxs,
                                                    "min_duration":mind,
                                                    "max_duration":maxd,
                                                    "validator_id":validid,
                                                    "name":nm,
                                                    "penalties":penalities,
                                                }   
                                    accelerationArray.push(value);
                                }                        
                            }

                            if(driving.brake !== undefined)
                            {
                                var y = driving.brake;
                                var brakeArray= [];
                                for(var b=0; b<y.length; b++)
                                {
                                    var flag1 = y[b].flags;
                                    var minv1= y[b].min_value;
                                    var maxv1= y[b].max_value;
                                    var mins1= y[b].min_speed;
                                    var maxs1= y[b].max_speed;
                                    var mind1= y[b].min_duration;
                                    var maxd1= y[b].max_duration;
                                    var validid1= y[b].validator_id;
                                    var nm1= y[b].name;
                                    var penalities1 =  y[b].penalties;
                                    var value1 = 
                                                {
                                                    "flags":flag1,
                                                    "min_value":minv1,
                                                    "max_value":maxv1,
                                                    "min_speed":mins1,
                                                    "max_speed":maxs1,
                                                    "min_duration":mind1,
                                                    "max_duration":maxd1,
                                                    "validator_id":validid1,   
                                                    "name":nm1,
                                                    "penalties":penalities1,
                                                }      
                                    brakeArray.push(value1);
                                }   
                            }

                            if(driving.turn !== undefined)
                            {
                                var t = driving.turn;
                                var turnArray= [];
                                for(var c=0; c<t.length; c++)
                                {
                                    var flag2 = t[c].flags;
                                    var minv2= t[c].min_value;
                                    var maxv2= t[c].max_value;
                                    var mins2= t[c].min_speed;
                                    var maxs2= t[c].max_speed;
                                    var mind2= t[c].min_duration;
                                    var maxd2= t[c].max_duration;
                                    var validid2= t[c].validator_id;
                                    var nm2= t[c].name;
                                    var penalities2 =  t[c].penalties;
                                    var value2 = 
                                                {
                                                    "flags":flag2,
                                                    "min_value":minv2,
                                                    "max_value":maxv2,
                                                    "min_speed":mins2,
                                                    "max_speed":maxs2,
                                                    "min_duration":mind2,
                                                    "max_duration":maxd2,
                                                    "validator_id":validid2,
                                                    "name":nm2,
                                                    "penalties":penalities2,
                                                }      
                                    turnArray.push(value2);                       
                                }   
                            }
                            
                            if(driving.sensor !== undefined)
                            {
                                var u = driving.sensor;
                                var sensorArray= [];
                                // var sensorName = "";
                                var sensr = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_unit","propName":"sys_id","propValueMask":unitID,"sortType":"sys_id",
                                        "propType":"property","or_logic":0},"force":0,"flags":4097,"from":0,"to":0});
                                var searchObject = sensr.items[0].sens;
                                for(let d=0; d<u.length; d++)
                                {
                                    let sensorName = ""; // Declare sensorName here
                                    var flag3 = u[d].flags;
                                    var minv3= u[d].min_value;
                                    var maxv3= u[d].max_value;
                                    var mins3= u[d].min_speed;
                                    var maxs3= u[d].max_speed;
                                    var mind3= u[d].min_duration;
                                    var maxd3= u[d].max_duration;
                                    var validid3= u[d].validator_id;
                                    var nm3= u[d].name;
                                    var penalities3 =  u[d].penalties;
                                    // sensor id from eco driving
                                    // var senid= s[w].sensor_id;                           
                                    // sensor id from sensors list
                                    var foundSensor = filenm.sensors.find(sensor => sensor.id === u[d].sensor_id);
                                    if (foundSensor) 
                                    {
                                        sensorName = foundSensor.n;
                                    }
                                    if(sensorName !== ""){                               
                                        const sName = Object.values(searchObject).find(item => item.n === sensorName);
                                        var sId = sName ? sName.id : 0;
                                    }                                                                         
                                    var value3 = 
                                                {
                                                    "flags":flag3,
                                                    "min_value":minv3,
                                                    "max_value":maxv3,
                                                    "min_speed":mins3,
                                                    "max_speed":maxs3,
                                                    "min_duration":mind3,
                                                    "max_duration":maxd3,
                                                    "validator_id":validid3,
                                                    "sensor_id":sId,
                                                    "name":nm3,
                                                    "penalties":penalities3,
                                                }         
                                    sensorArray.push(value3);
                                }   
                            }

                            if(driving.speeding !== undefined)
                            {
                                var v = driving.speeding;
                                var speedArray= [];
                                for(var e=0; e<v.length; e++)
                                {
                                    var flag4 = v[e].flags;
                                    var minv4= v[e].min_value;
                                    var maxv4= v[e].max_value;
                                    var mins4= v[e].min_speed;
                                    var maxs4= v[e].max_speed;
                                    var mind4= v[e].min_duration;
                                    var maxd4= v[e].max_duration;
                                    var validid4= v[e].validator_id;
                                    var nm4= v[e].name;
                                    var penalities4 =  v[e].penalties;
                                    var value4 = 
                                                {
                                                    "flags":flag4,
                                                    "min_value":minv4,
                                                    "max_value":maxv4,
                                                    "min_speed":mins4,
                                                    "max_speed":maxs4,
                                                    "min_duration":mind4,
                                                    "max_duration":maxd4,
                                                    "validator_id":validid4,
                                                    "name":nm4,
                                                    "penalties":penalities4,
                                                }   
                                    speedArray.push(value4);
                                }   
                            }

                            if(driving.harsh !== undefined)
                            {
                                var x = driving.sensor;
                                var harshArray= [];
                                for(var w=0; w<x.length; w++)
                                {
                                    var flag5 = x[w].flags;
                                    var minv5= x[w].min_value;
                                    var maxv5= x[w].max_value;
                                    var mins5= x[w].min_speed;
                                    var maxs5= x[w].max_speed;
                                    var mind5= x[w].min_duration;
                                    var maxd5= x[w].max_duration;
                                    var validid5= x[w].validator_id;
                                    var nm5= x[w].name;
                                    var penalities5 =  x[w].penalties;
                                    var value5 = 
                                                {
                                                    "flags":flag5,
                                                    "min_value":minv5,
                                                    "max_value":maxv5,
                                                    "min_speed":mins5,
                                                    "max_speed":maxs5,
                                                    "min_duration":mind5,
                                                    "max_duration":maxd5,
                                                    "validator_id":validid5,
                                                    "name":nm5,
                                                    "penalties":penalities5,
                                                }   
                                    harshArray.push(value5);
                                }   
                            }
                            
                            var params={"itemId":unitID,
                                        "driveRank":
                                            {
                                                "acceleration":accelerationArray?accelerationArray:[],
                                                "brake":brakeArray? brakeArray:[],
                                                "turn":turnArray? turnArray:[],
                                                "sensor":sensorArray? sensorArray:[],
                                                "speeding":speedArray ? speedArray : [],
                                                "harsh":harshArray ? harshArray : [],
                                                "global": {"accel_mode":accelmode}
                                            }}

                            try
                            {
                                var unit = await asyncRemoteCall("unit/update_drive_rank_settings",params);
                                if(unit.length ===undefined){
                                    setSuccessEco(prev => [...prev,`Eco driving added successfully for "${UnitName}"`]);
                                } 
                                else{
                                    setLog(prev => [...prev,`Failed to add eco driving for the unit "${UnitName}".`]);
                                }                      
                            } 
                            catch(error) 
                            {
                                setLog(prev => [...prev,`Failed to add eco driving for the unit "${UnitName}"`]);
                            } 
                        }
                    } // end of eco driving


                    var userId = props.selectedUserId;
                    var resourceid = props.adminResourceId;
                    var adminId = props.AdminId;
                    var dispatcherId = props.DispatcherId;
                    var managerId = props.ManagerId;
                    
                    if (companyname === "lvm")
                    {
                        console.log(UnitGroup1);
                        console.log(UnitGroup2);
                        console.log(UnitGroup3);
                        if(UnitGroup1 === "LVM Hologram SIMs" || UnitGroup1 === "Bell Jasper SIMs")
                        {
                            await filter(UnitGroup1);
                        }    
                        if(UnitGroup2 === "LVM Hologram SIMs" || UnitGroup2 === "Bell Jasper SIMs")
                        {
                            await filter(UnitGroup2);
                        }
                        if(UnitGroup3 === "LVM Hologram SIMs" || UnitGroup3 === "Bell Jasper SIMs")
                        {
                            await filter(UnitGroup3);
                        }       
                    }
                                
                    async function filter(UnitGroup)
                    {
                        console.log(UnitGroup);
                        var unitA = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_unit_group","propName":"sys_name","propValueMask":UnitGroup,
                        "sortType":"sys_name","propType":"","or_logic":0},"force":0,"flags":1,"from":0,"to":0});
                        console.log(unitA);
                        if(unitA.totalItemsCount > 0){
                            var fid= unitA.items[0].id;
                            console.log(createdUnitGroups);
                            createdUnitGroups = createdUnitGroups.filter(item => item !== fid);
                            console.log(createdUnitGroups); 
                        }                                         
                    }
     
                    // presets applied to 4 users only when create option is selected
                    if((userId !== undefined) && (adminId!== undefined) && (dispatcherId!== undefined) && (managerId !== undefined)
                        && (resourceid !== undefined))
                    {
                       await CreatePresets(userId,adminId,dispatcherId,managerId,resourceid,unitIdd,createdUnitGroups,measuring);
                    }                                      
                    if((userId !== undefined) && ((adminId === undefined) && (dispatcherId=== undefined) && (managerId=== undefined)
                     && (resourceid === undefined)))
                    {
                        await ExistingPresets(userId,unitIdd,createdUnitGroups);
                    }                                      
            } // end of findWlpWithUnitidAndHwName

           async function ExistingPresets(userId,unitID,createdUnitGroups)
           {
                await UnitPresetsToUser(unitID,userId);               
                await UnitGroupPresetsToUser(userId);

                //Users
                async function UnitPresetsToUser(key,userId)
                {       
                    var params = {"userId":userId, "itemId":key,"accessMask": 844889128951};
                    try
                    {
                        var unit1 = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        if (Object.keys(unit1).length !== 0) {
                            setLog(prev => [...prev,`Unable to assign unit presets of "${UnitName}" for the existing user`]);
                        }
                        else{
                            setSuccess(prev => [...prev,`Successfully assigned unit presets of "${UnitName}" for existing user`]);
                        }                      
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`We encountered an issue while granting unit presets  of "${UnitName}" for existing user`]);
                    }
                }
                
                async function UnitGroupPresetsToUser(userId)
                {
                    for(var d = 0; d < createdUnitGroups.length; d++ )
                    {
                        var params = {"userId":userId, "itemId":createdUnitGroups[d],"accessMask": 844889128951};
                        try
                        {            
                            var unit2 = await asyncRemoteCall("user/update_item_access",params);
                            //console.log(unit2);
                            if (Object.keys(unit2).length !== 0) {
                                setLog(prev => [...prev,`Unable to assign unit group presets for the existing user`]);
                            }
                            else{
                                setSuccess(prev => [...prev,`Successfully assigned unit group presets for existing user`]);
                            }
                            
                        } 
                        catch(error)
                        {
                            setLog(prev => [...prev,`We encountered an issue while granting unit group presets for existing user`]);
                        }
                    }                   
                }
                
                await SupportAccess(unitID);
            } // end of existing presets function

           async function CreatePresets(userId,adminId,dispatcherId,managerId,resourceId,unitID,createdUnitGroups,measuring)
           {
                // Giving User - admin units, admin unitgroup, admin resource,IFTA resource, template report presets access.
                var iftaId;
                try
                {
                    var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_resource","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
                    if(unit.totalItemsCount > 0){
                        for ( var n = 0; n < unit.items.length;n++)
                            {
                                if(measuring === "Metric")
                                {
                                    if(unit.items[n].nm === "IFTA Reports")
                                    {
                                        iftaId = unit.items[n].id;
                                        //console.log(iftaId);
                                    }                                 
                                }
                                else if(measuring === "U.S.")
                                {
                                    if(unit.items[n].nm === "IFTA Reports US")
                                    {
                                        iftaId = unit.items[n].id;
                                        //console.log(iftaId);
                                    }
                                }                               
                            }
                    }
                    else{
                        setLog(prev => [...prev,`IFTA resource not found. Access to IFTA resource for user, admin, dispatcher, and manager roles cannot be granted.`]);
                    }
                    
                }
                catch(error)
                {
                    setLog(prev => [...prev,`IFTA resource not found and access to IFTA resource for user, admin, dispatcher, and manager roles cannot be granted.`]);
                }           
                // For User        
                await UnitPresetsToUser(unitID,userId);
                await UnitGroupPresetsToUser(userId);
                                      
                // For Admin                                           
                await UnitPresetsToAdmin(unitID,adminId);                    
                await UnitGroupPresetsToAdmin(adminId);
                      
                // For Dispatcher
                await UnitPresetsToDispatcher(unitID,dispatcherId);
                await UnitGroupPresetsToDispatcher(dispatcherId);

                // For Manager
                await UnitPresetsToManager(unitID,managerId);
                await UnitGroupPresetsToManager(managerId);

                if (IFTAinExcel === "Yes")
                {
                    if(measuring === "Metric" || measuring === "U.S.")
                    {
                        await IftaResourcePresetsToUser(iftaId,userId);
                        await IftaResourcePresetsToAdmin(iftaId,adminId);
                        await IftaResourcePresetsToDispatcher(iftaId,dispatcherId);
                        await IftaResourcePresetsToManager(iftaId,managerId);
                    }                           
                }  

                //Users
                async function UnitPresetsToUser(key,userId)
                {   
                    var params = {"userId":userId, "itemId":key,"accessMask": 844889128951};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`User presets applied successfully for unit "${UnitName}"`]);
                        }
                        else{
                            setLog(prev => [...prev,`Failed to add user presets for the unit "${UnitName}".`]);
                        }                           
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add user presets for the unit "${UnitName}"`]);                               
                    }
                }

                async function UnitGroupPresetsToUser(userId)
                {
                    console.log(createdUnitGroups);
                    for(var d = 0; d < createdUnitGroups.length; d++ )
                    {
                        var params = {"userId":userId, "itemId":createdUnitGroups[d],"accessMask": 844889128951};
                        try
                        {
                            var unit = await asyncRemoteCall("user/update_item_access",params);
                            //console.log(unit);
                            if(unit.length === undefined){
                                setSuccess(prev => [...prev,`User presets applied successfully for unit group of unit "${UnitName}"`]);
                            }  
                            else{
                                setLog(prev => [...prev,`Failed to add user presets for unit group of unit "${UnitName}".`]);
                            }                             
                        } 
                        catch(error)
                        {
                            setLog(prev => [...prev,`Failed to add user presets for unit groups of unit "${UnitName}"`]);
                        }
                    }                          
                }                        
                //Admin
                async function UnitPresetsToAdmin(key,adminId)
                {  
                    console.log(createdUnitGroups);
                    var params = {"userId":adminId, "itemId":key,"accessMask": 844889128951};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Admin user presets applied successfully for unit "${UnitName}"`]);
                        }  
                        else
                        {
                            setLog(prev => [...prev,`Failed to add admin user presets for the unit "${UnitName}".`]);
                        }                         
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add admin user presets for the unit "${UnitName}"`]);
                    }
                }
                        
                async function UnitGroupPresetsToAdmin(adminId)
                {                          
                    for(var d = 0; d < createdUnitGroups.length; d++ )
                     {                                
                        var params = {"userId":adminId, "itemId":createdUnitGroups[d],"accessMask": 844889128951};
                        try
                         {
                            var unit = await asyncRemoteCall("user/update_item_access",params);
                            //console.log(unit);
                            if(unit.length === undefined){
                                setSuccess(prev => [...prev,`Admin user presets applied successfully for unit group of unit "${UnitName}"`]);
                            } 
                            else{
                                setLog(prev => [...prev,`Failed to add admin user presets for unit group of unit "${UnitName}".`]);
                            
                            }
                        } 
                        catch(error)
                        {
                            setLog(prev => [...prev,`Failed to add admin user presets for unit groups of unit "${UnitName}"`]);
                        }
                     }                           
                }
                // Dispatcher
                async function UnitPresetsToDispatcher(key,dispatcherId)
                {   
                    var params = {"userId":dispatcherId, "itemId":key,"accessMask": 567304782371};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Dispatcher user presets applied successfully for unit "${UnitName}"`]);
                        }
                        else{
                            setLog(prev => [...prev,`Failed to add dispatcher user presets for the unit "${UnitName}".`]);
                        }                               
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add dispatcher user presets for the unit "${UnitName}"`]);
                    }
                }
                       
                async function UnitGroupPresetsToDispatcher(dispatcherId)
                {
                    for(var d = 0; d < createdUnitGroups.length; d++ )
                    {
                        var params = {"userId":dispatcherId, "itemId":createdUnitGroups[d],"accessMask": 567304782371};
                        try
                        {                    
                            var unit = await asyncRemoteCall("user/update_item_access",params);
                            //console.log(unit);
                            //console.log(unit.length);
                            if(unit.length === undefined){
                                setSuccess(prev => [...prev,`Dispatcher user presets applied successfully for unit group of unit "${UnitName}"`]);
                            }   
                            else{
                                setLog(prev => [...prev,`Failed to add dispatcher user presets for unit group of unit "${UnitName}"`]);
                            }                                
                        } 
                        catch(error)
                        {
                            setLog(prev => [...prev,`Failed to add dispatcher user presets for unit groups of unit "${UnitName}"`]);
                        }
                    }                          
                }                      
                //Manager
                async function UnitPresetsToManager(key,managerId)
                {   
                    var params = {"userId":managerId, "itemId":key,"accessMask": 567863703543};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Manager user presets applied successfully for unit "${UnitName}"`]);
                        } 
                        else{
                            setLog(prev => [...prev,`Failed to add manager user presets for unit "${UnitName}"`]);
                        }                              
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add manager user presets for unit "${UnitName}"`]);
                    }
                }                       
                async function UnitGroupPresetsToManager(managerId)
                {
                    for(var d = 0; d < createdUnitGroups.length; d++ )
                    {
                        var params = {"userId":managerId, "itemId":createdUnitGroups[d],"accessMask": 567863703543};
                        try
                        {                    
                            var unit = await asyncRemoteCall("user/update_item_access",params);
                            //console.log(unit);
                            //console.log(unit.length);
                            if(unit.length === undefined){
                                setSuccess(prev => [...prev,`Manager user presets applied successfully for unit group of unit "${UnitName}"`]);
                            } 
                            else{
                                setLog(prev => [...prev,`Failed to add manager user presets for unit group of unit "${UnitName}"`]);
                            }                                  
                        } 
                        catch(error)
                        {
                            setLog(prev => [...prev,`Failed to add manager user presets for unit groups of unit "${UnitName}"`]);
                        }
                    }                           
                }

                //Previusly IFTA resource to every user was given 486556199 which is IFTA presets. It is wrong. Change that to 16931
                async function IftaResourcePresetsToUser(iftaId,userId)
                {
                    console.log(userId);
                    console.log(iftaId);
                    var params = {"userId":userId, "itemId":iftaId,"accessMask": 486556199 };
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`IFTA resource presets applied successfully for user`]);
                        }
                        else{
                            setLog(prev => [...prev,`Failed to add IFTA resource presets for User.`]);
                        }                               
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add IFTA resource presets for User`]);
                    }
                }
                async function IftaResourcePresetsToAdmin(iftaId,adminId)
                {
                    var params = {"userId":adminId, "itemId":iftaId,"accessMask": 486556199};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`IFTA resource presets applied successfully for admin user`]);
                        }  
                        else{
                            
                            setLog(prev => [...prev,`Failed to add IFTA Resource presets for Admin User.`]);
                        }
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add IFTA Resource presets for Admin User`]);
                    }
                }
                async function IftaResourcePresetsToDispatcher(iftaId,dispatcherId)
                {
                    var params = {"userId":dispatcherId, "itemId":iftaId,"accessMask": 486556199};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`IFTA resource presets applied successfully for dispatcher user`]);
                        }  
                        else{
                            setLog(prev => [...prev,`Failed to add IFTA Resource presets for Dispatcher User.`]);
                        }                             
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add IFTA Resource presets for Dispatcher User`]);
                    }
                }
                async function IftaResourcePresetsToManager(iftaId,managerId)
                {
                    var params = {"userId":managerId, "itemId":iftaId,"accessMask": 486556199};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`IFTA resource presets applied successfully for manager user`]);
                        }
                        else{
                            setLog(prev => [...prev,`Failed to add IFTA Resource presets for Manager User.`]);
                        }                              
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to add IFTA resource presets for manager user`]);
                    }
                }

                await SupportAccess(unitID,createdUnitGroups);
            } // end of CreatePresets function
                               
            //giving access to support people             
            async function SupportAccess(unitID,createdUnitGroups)
            {
                // different admin users (support users) name based on the accounts used
                var user1 = "";
                var user2 = "";
                var user3 = "";
                var user4 = "";
                var user5 = "";

                var org = "";
                var c1= "lvm";
                var c2 = "google";
                var c3 = "astreon";
                var isGoogle = false;
                if(props.loggedUser.toLowerCase().includes(c2.toLowerCase()))
                {
                    isGoogle = true;
                }
                if(props.loggedUser.toLowerCase().includes(c1.toLowerCase()))
                {
                    org = "lvm";
                }
                else if(props.loggedUser.toLowerCase().includes(c3.toLowerCase()))
                {
                    org = "astreon";
                }

                if (org === c1 && isGoogle === false)
                {
                    user1 = "dtravis";
                    user2 = "jimmanuel";
                    user3 = "jsuvagiya";
                    user4 = "hkaur";
                    user5 = "bbarratt";
                }
                else if (org === c1 && isGoogle === true)
                {
                    user1 = "dtravislvmgoogle";
                    user2 = "jimmanuellvmgoogle";
                    user3 = "jsuvagiyalvmgoogle";
                    user4 = "hkaurlvmgoogle";
                    user5 = "bbarrattlvmgoogle";
                }
                else if (org === c3 && isGoogle === false)
                {
                    user1 = "dtravisastreon";
                    user2 = "jsuvagiyaastreon";
                    user3 = "jsuvagiyaastreon";
                    user4 = "hkaurastreon";
                    user5 = "bbarrattastreon";
                }
                else if (org === c3 && isGoogle === true)
                {
                    user1 = "dtravisastreongoogle";
                    user2 = "jimmanuelastreongoogle";
                    user3 = "jsuvagiyaastreongoogle";
                    user4 = "hkaurastreongoogle";
                    user5 = "bbarrattastreongoogle";
                }
                
                try
                {
                    var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"user","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
                    if(unit.totalItemsCount > 0){
                        for ( var n = 0; n < unit.items.length;n++)
                        {
                            if(unit.items[n].nm === user1){
                                    var user1Id = unit.items[n].id;
                                    var user1Nm = unit.items[n].nm;
                                    //console.log(user1Id);   
                                    await UnitAccessToSupport(user1Id,user1Nm); 
                                    await UnitGroupAccessToSupport(user1Id,user1Nm);                           
                            }
                            if(unit.items[n].nm === user2){
                                    var user2Id = unit.items[n].id;
                                    var user2Nm = unit.items[n].nm;
                                    //console.log(user2Id);   
                                    await UnitAccessToSupport(user2Id,user2Nm); 
                                    await UnitGroupAccessToSupport(user2Id,user2Nm);                                 
                            }
                            if(unit.items[n].nm === user3){
                                    var user3Id = unit.items[n].id;
                                    var user3Nm = unit.items[n].nm;
                                    //console.log(user3Id); 
                                    await UnitAccessToSupport(user3Id,user3Nm);  
                                    await UnitGroupAccessToSupport(user3Id,user3Nm);                                
                            }
                            if(unit.items[n].nm === user4){
                                    var user4Id = unit.items[n].id;
                                    var user4Nm = unit.items[n].nm;
                                    //console.log(user4Id); 
                                    await UnitAccessToSupport(user4Id,user4Nm);
                                    await UnitGroupAccessToSupport(user4Id,user4Nm);                                   
                            }
                            if(unit.items[n].nm === user5){
                                    var user5Id = unit.items[n].id;
                                    var user5Nm = unit.items[n].nm;
                                    //console.log(user5Id);
                                    await UnitAccessToSupport(user5Id,user5Nm);   
                                    await UnitGroupAccessToSupport(user5Id,user5Nm);                                    
                            }
                        }   
                    }                                    
                }
                catch(error)
                {
                    setLog(prev => [...prev,`Failed to retrieve details of support users. As a result, access to newly created units and unit groups cannot be granted.`]);
                }

                // giving these users full access to created units, unit groups, created users and own resource
                // for created units                   
                async function UnitAccessToSupport(SupportUserId,SupportUserName)
                {     
                    var params = {"userId":SupportUserId, "itemId":unitID,"accessMask": 880333094911};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Support user "${SupportUserName}" successfully given rights for unit "${UnitName}"`]);
                        }  
                        else{
                            setLog(prev => [...prev,`Failed to give rights of units for support user "${SupportUserName}".`]);
                        }                                  
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to give rights of units for support user "${SupportUserName}"`]);
                    }                       
                }
                // for created unit groups
                async function UnitGroupAccessToSupport(SupportUserId,SupportUserName)
                {                           
                    for(var d = 0; d < createdUnitGroups.length; d++ )
                    {                               
                        var params = {"userId":SupportUserId, "itemId":createdUnitGroups[d],"accessMask": 880333094911};
                        try
                        {                       
                            var unit = await asyncRemoteCall("user/update_item_access",params);
                            //console.log(unit);
                            //console.log(unit.length);
                            if(unit.length === undefined){
                                setSuccess(prev => [...prev,`Support user "${SupportUserName}" successfully given rights for unit group of the unit "${UnitName}"`]);
                            }     
                            else{
                                setLog(prev => [...prev,`Failed to give rights of unit groups for support user "${SupportUserName}".`]);
                            }                              
                        } 
                        catch(error)
                        {
                            setLog(prev => [...prev,`Failed to give rights of unit groups for support user "${SupportUserName}"`]);
                        }
                    }                               
                }
            } // end of SupportAccess                        
        } //end of mapping excel data

        await others();

        // only when creating a new account
        async function others()
        {
            var resourceid = props.adminResourceId;
            //console.log(resourceid);
            var userId = props.selectedUserId;
            var resourceId = props.adminResourceId;
            var adminId = props.AdminId;
            var dispatcherId = props.DispatcherId;
            var managerId = props.ManagerId;

            if((userId !==undefined) && (adminId !==undefined) && (dispatcherId !==undefined) && ( managerId !==undefined)
             && (resourceid !==undefined))
            {
                var measuring = props.measurement;
                var templateReportsId;
                try
                {
                    var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_resource","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
                    //console.log(unit);
                    if(unit.totalItemsCount > 0){

                        for ( var n = 0; n < unit.items.length;n++)
                        {
                            if(measuring === "Metric")
                            {                                        
                                if(unit.items[n].nm === "Template Reports")
                                {
                                    templateReportsId = unit.items[n].id;
                                    //console.log(templateReportsId);                                       
                                }
                            }
                            else if(measuring === "U.S.")
                            {                                        
                                if(unit.items[n].nm === "Template US Reports")
                                {
                                    templateReportsId = unit.items[n].id;
                                    //console.log(templateReportsId);                                       
                                }
                            }                                   
                        }
                    }                                  
                }
                catch(error)
                {
                    setLog(prev => [...prev,`An error occurred while processing template reports. As a result, access to template reports for user, admin, dispatcher, and manager roles cannot be granted.`]);
                }

                await ResourcePresetsToUser(resourceId,userId);
                
                await ResourcePresetsToAdmin(resourceId,adminId);                        
                await ResourcePresetsToDispatcher(resourceId,dispatcherId);
                //await TemplateReportPresetsToDispatcher(templateReportsId,dispatcherId);
                await ResourcePresetsToManager(resourceId,managerId);
                   
                if(measuring === "Metric" || measuring === "U.S.")
                {
                    await TemplateReportPresetsToUser(templateReportsId,userId);
                    await TemplateReportPresetsToAdmin(templateReportsId,adminId);
                    await TemplateReportPresetsToManager(templateReportsId,managerId);
                }

                async function ResourcePresetsToUser(resourceId,userId)
                {
                    var params = {"userId":userId, "itemId":resourceId,"accessMask": 52913983520759};
                    try
                    {   
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`User presets applied for resource`]);
                        }  
                        else{
                            setLog(prev => [...prev,`Failed to apply user presets for resource.`]);
                        }                                  
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to apply user presets for resource`]);
                    }
                }
                                  
                async function TemplateReportPresetsToUser(templateReportsId,userId)
                {
                    //268453415
                    var params = {"userId":userId, "itemId":templateReportsId,"accessMask": 268453415};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`User presets applied for template resource`]);
                        } 
                        else{
                            setLog(prev => [...prev,`Failed to apply user presets for template resource.`]);
                        }                                  
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to apply user presets for template resource`]);
                    }
                }      
                async function ResourcePresetsToAdmin(resourceId,adminId)
                {
                    var params = {"userId":adminId, "itemId":resourceId,"accessMask": 52913983520759};
                    try
                    {    
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Admin user presets applied for resource`]);
                        }  
                        else{
                            setLog(prev => [...prev,`Failed to apply admin user presets for resource.`]);
                        }                                  
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to apply admin user presets for resource`]);
                    }
                }
                                   
                async function TemplateReportPresetsToAdmin(templateReportsId,adminId)
                {                                              
                    var params = {"userId":adminId, "itemId":templateReportsId,"accessMask": 268453415};
                    try
                    {   
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Admin user presets applied for template resource`]);
                        }     
                        else{
                            setLog(prev => [...prev,`Failed to apply admin user presets for template resource.`]);
                        }                               
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to apply admin user Presets for template resource`]);
                    }
                }   
                async function ResourcePresetsToDispatcher(resourceId,dispatcherId)
                {
                    var params = {"userId":dispatcherId, "itemId":resourceId,"accessMask": 17653742715427};
                    try
                    {   
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Dispatcher user presets applied for resource`]);
                        }   
                        else{
                            setLog(prev => [...prev,`Failed to apply dispatcher user presets for resource.`]);
                        }                                 
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to apply dispatcher user presets for resource`]);
                    }
                }                         
                /*
                async function TemplateReportPresetsToDispatcher(templateReportsId,dispatcherId)
                {                                              
                    var params = {"userId":dispatcherId, "itemId":templateReportsId,"accessMask": 269501991};
                    try
                    {  
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        console.log(unit);
                        console.log("Dispatcher- Template Resource presets applied successfully: ");
                    } 
                    catch(error)
                    {
                        console.log("Failed to add Dispatcher- Template Resource presets: ", error);
                    }
                }
                */                          
                async function ResourcePresetsToManager(resourceId,managerId)
                {
                    var params = {"userId":managerId, "itemId":resourceId,"accessMask": 52913983485943};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Manager user presets applied for resource`]);
                        } 
                        else{
                            setLog(prev => [...prev,`Failed to apply manager user presets for resource.`]);
                        }                                  
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to apply manager user presets for resource`]);
                    }
                }
    
                async function TemplateReportPresetsToManager(templateReportsId,managerId)
                {                       
                    var params = {"userId":managerId, "itemId":templateReportsId,"accessMask": 268453415};
                    try
                    {    
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Manager user presets applied for template resource`]);
                        } 
                        else{
                            setLog(prev => [...prev,`Failed to apply manager user presets for template resource.`]);    
                        }                                  
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to apply manager user presets for template resource`]);
                    }
                }

                // different admin users (support users) name based on the accounts used
                var usr1 = "";
                var usr2 = "";
                var usr3 = "";
                var usr4 = "";
                var usr5 = "";

                var comp = "";
                var o1= "lvm";
                var o2 = "google";
                var o3 = "astreon";
                var isGoogle = false;
                if(props.loggedUser.toLowerCase().includes(o2.toLowerCase()))
                {
                    isGoogle = true;
                }
                if(props.loggedUser.toLowerCase().includes(o1.toLowerCase()))
                {
                    comp = "lvm";
                }
                else if(props.loggedUser.toLowerCase().includes(o3.toLowerCase()))
                {
                    comp = "astreon";
                }

                if (comp === o1 && isGoogle === false)
                {
                    usr1 = "dtravis";
                    usr2 = "jimmanuel";
                    usr3 = "jsuvagiya";
                    usr4 = "hkaur";
                    usr5 = "bbarratt";
                }
                else if (comp === o1 && isGoogle === true)
                {
                    usr1 = "dtravislvmgoogle";
                    usr2 = "jimmanuellvmgoogle";
                    usr3 = "jsuvagiyalvmgoogle";
                    usr4 = "hkaurlvmgoogle";
                    usr5 = "bbarrattlvmgoogle";
                }
                else if (comp === o3 && isGoogle === false)
                {
                    usr1 = "dtravisastreon";
                    usr2 = "jsuvagiyaastreon";
                    usr3 = "jsuvagiyaastreon";
                    usr4 = "hkaurastreon";
                    usr5 = "bbarrattastreon";
                }
                else if (comp === o3 && isGoogle === true)
                {
                    usr1 = "dtravisastreongoogle";
                    usr2 = "jimmanuelastreongoogle";
                    usr3 = "jsuvagiyaastreongoogle";
                    usr4 = "hkaurastreongoogle";
                    usr5 = "bbarrattastreongoogle";
                }
                try
                {
                    var unt = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"user","propName":"sys_name","propValueMask":"*","sortType":"sys_name","propType":"","or_logic":0},"force":1,"flags":1,"from":0,"to":0});
                    if(unt.totalItemsCount > 0){
                        for ( var m = 0; m < unt.items.length;m++)
                            {
                                if(unt.items[m].nm === usr1){
                                    var user1Id = unt.items[m].id;
                                    var user1Nm = unt.items[m].nm;
                                    //console.log(user1Id);    
                                    await ResourceAccessToSupport(user1Id,user1Nm); 
                                    await UserAccessToSupport(user1Id,user1Nm); 
                                    await AdminUserAccessToSupport(user1Id,user1Nm);
                                    await DispatcherAccessToSupport(user1Id,user1Nm);
                                    await ManagerAccessToSupport(user1Id,user1Nm); 
                                }
                                if(unt.items[m].nm === usr2){
                                    var user2Id = unt.items[m].id;
                                    var user2Nm = unt.items[m].nm;
                                    //console.log(user2Id);   
                                    await ResourceAccessToSupport(user2Id,user2Nm);
                                    await UserAccessToSupport(user2Id,user2Nm);   
                                    await AdminUserAccessToSupport(user2Id,user2Nm);
                                    await DispatcherAccessToSupport(user2Id,user2Nm);
                                    await ManagerAccessToSupport(user2Id,user2Nm);     
                                }
                                if(unt.items[m].nm === usr3){
                                    var user3Id = unt.items[m].id;
                                    var user3Nm = unt.items[m].nm;
                                    //console.log(user3Id);                                        
                                    await ResourceAccessToSupport(user3Id,user3Nm);
                                    await UserAccessToSupport(user3Id,user3Nm);     
                                    await AdminUserAccessToSupport(user3Id,user3Nm);
                                    await DispatcherAccessToSupport(user3Id,user3Nm);
                                    await ManagerAccessToSupport(user3Id,user3Nm);       
                                }
                                if(unt.items[m].nm === usr4){
                                    var user4Id = unt.items[m].id;
                                    var user4Nm = unt.items[m].nm;
                                    //console.log(user4Id);                                        
                                    await ResourceAccessToSupport(user4Id,user4Nm);
                                    await UserAccessToSupport(user4Id,user4Nm);
                                    await AdminUserAccessToSupport(user4Id,user4Nm);
                                    await DispatcherAccessToSupport(user4Id,user4Nm);
                                    await ManagerAccessToSupport(user4Id,user4Nm);             
                                }
                                if(unt.items[m].nm === usr5){
                                    var user5Id = unt.items[m].id;
                                    var user5Nm = unt.items[m].nm;
                                    //console.log(user5Id);                                     
                                    await ResourceAccessToSupport(user5Id,user5Nm);
                                    await UserAccessToSupport(user5Id,user5Nm);
                                    await AdminUserAccessToSupport(user5Id,user5Nm);
                                    await DispatcherAccessToSupport(user5Id,user5Nm);
                                    await ManagerAccessToSupport(user5Id,user5Nm);         
                                }
                            }   
                    }

                                            
                }
                catch(error)
                {
                    setLog(prev => [...prev,`An error occurred while trying to retrieve the details of the support user. As a result, access to the admin user, dispatcher, manager, and newly created account cannot be granted.`]);
                }
                            
                // giving these users full access to created users and own resource
                // for own created resource
                async function ResourceAccessToSupport(SupportUserId,SupportUserName)
                {
                    var params = {"userId":SupportUserId, "itemId":resourceId,"accessMask": 52913983520767};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Support user "${SupportUserName}" given rights for resource`]);
                        }
                        else{
                            setLog(prev => [...prev,`Failed to give rights of resource for support user "${SupportUserName}".`]);
                        }                               
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to give rights of resource for support user "${SupportUserName}"`]);
                    }
                }
                async function UserAccessToSupport(SupportUserId,SupportUserName)
                {
                    var params = {"userId":SupportUserId, "itemId":userId,"accessMask": 7405567};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Support user "${SupportUserName}" given rights for newly created User`]);
                        }   
                        else{
                            setLog(prev => [...prev,`Failed to give rights of newly created User for support user "${SupportUserName}".`]);
                        }                             
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to give rights of newly created User for support user "${SupportUserName}"`]);
                    }
                }
                // for created 3 users -admin
                async function AdminUserAccessToSupport(SupportUserId,SupportUserName)
                {                   
                    var params = {"userId":SupportUserId, "itemId":adminId,"accessMask": 7405567};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Support user "${SupportUserName}" given rights for admin user`]);
                        }  
                        else{
                            setLog(prev => [...prev,`Failed to give rights of admin user for support user "${SupportUserName}".`]);
                        }                             
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to give rights of admin user for support user "${SupportUserName}"`]);
                    }
                }
                // for created 3 users - dispatcher
                async function DispatcherAccessToSupport(SupportUserId,SupportUserName)
                {                                              
                    var params = {"userId":SupportUserId, "itemId":dispatcherId,"accessMask": 7405567};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Support User "${SupportUserName}" given rights for dispatcher user`]);
                        } 
                        else{
                            setLog(prev => [...prev,`Failed to give rights of dispatcher user for support user "${SupportUserName}".`]);
                        }                             
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to give rights of dispatcher user for support user "${SupportUserName}"`]);
                    }
                }
                // for created 3 users - Manager
                async function ManagerAccessToSupport(SupportUserId,SupportUserName)
                {
                    var params = {"userId":SupportUserId, "itemId":managerId,"accessMask": 7405567};
                    try
                    {
                        var unit = await asyncRemoteCall("user/update_item_access",params);
                        //console.log(unit);
                        //console.log(unit.length);
                        if(unit.length === undefined){
                            setSuccess(prev => [...prev,`Support user "${SupportUserName}" given rights for manager user`]);
                        } 
                        else{
                            setLog(prev => [...prev,`Failed to give rights of manager user for support user "${SupportUserName}".`]);
                        }                               
                    } 
                    catch(error)
                    {
                        setLog(prev => [...prev,`Failed to give rights of manager user for support user "${SupportUserName}"`]);                                
                    }
                }                                              
            } // end of if                
        } // end of others function
        // if(unitsCreatedCount === 0)
        // {
        //     setUnitsCount(prev => [...prev,`No units have been created yet.`]);
        // }
        setIsLoading(false);
        //handleDownloadClick();  
    } //end of init function
   
    

    // to prevent accidentally closing tab/ refresh
    window.onbeforeunload = (event) => 
    {
        const e = event || window.event;
        e.preventDefault(); // Cancel the event
        if (e) 
        {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
    };

    const handleClearTable =() =>
    {
          setUnitNames([""]);
          setPhone([""]);
          setVin([""]);
          setDeviceid([""]);
          setUid([""]);
          setUnittype([""]);
          setVehicletype([""]);
          setPlate([""]);
    }
    const handleClearLog =() =>
    {
          setUnitsCount([""]);
          setSuccess([""]);
          setsuccessCustom([""]);
          setSuccessSensors([""]);
          setsuccessUnitGroups([""]);
          setLog([""]);
          setSuccessEngine([""]);
          setSuccessTrip([""]);
          setSuccessAdvanced([""]);
          setSuccessEco([""]);
          setSuccessMileage([""]);
          setSuccessHwConfig([""]);
    }

    // downloading all log messages
    function generateDownloadContent() 
    {
        let content = '';
        content += 'Log:\n';
        log.forEach((message) => 
        {
          content += message + '\n';
        });
        
        content += '\n\n';
        unitsCount.forEach((message) => 
        {
          content += message + '\n';
        });

        content += '\n\n';
        content += 'Status:\n';
        success.forEach((message) => 
        {
          content += message + '\n';
        });

        content += '\n\n';
        content += 'Added UnitGroups:\n';
        successUnitGroups.forEach((ug) =>
         {
          content += ug + '\n';
        });
       
        content += '\n\n';
        content += 'Added Custom Fields :\n';
        successCustom.forEach((custom) => 
        {
          content += custom + '\n';
        });

        content += '\n\n';
        content += 'Added Sensors :\n';
        successSensors.forEach((sens) => 
        {
          content += sens + '\n';
        });

        content += '\n\n';
        content += 'Added Hardware Configurations :\n';
        successHwConfig.forEach((sens) => 
        {
          content += sens + '\n';
        });

        content += '\n\n';
        content += 'Added Advanced Properties :\n';
        successAdvanced.forEach((sens) => 
        {
          content += sens + '\n';
        });

        content += '\n\n';
        content += 'Added Mileage Counter :\n';
        successMileage.forEach((sens) => 
        {
          content += sens + '\n';
        });

        content += '\n\n';
        content += 'Added Engine Hours Counter :\n';
        successEngine.forEach((sens) => 
        {
          content += sens + '\n';
        });

        content += '\n\n';
        content += 'Added Trip Detection :\n';
        successTrip.forEach((sens) => 
        {
          content += sens + '\n';
        });

        content += '\n\n';
        content += 'Added Eco Driving:\n';
        successEco.forEach((sens) => 
        {
          content += sens + '\n';
        });
        return content;
    }

    function handleDownloadClick() 
    {
        const content = generateDownloadContent();
        const link = document.createElement('a');
        const file = new Blob([content], {type: 'text/plain'});
        link.href = URL.createObjectURL(file);
        link.download = 'Log.txt';
        document.body.appendChild(link);  // Append to html link element page
        link.click(); // Start download
    }


    return (
      <div>   
            {isLoading ? (
                <center> 
                    <PacmanLoader color={'#1063a9'} loading={isLoading} size={25} aria-label="Loading Spinner" data-testid="loader"/>
                </center>               
            ):(
                <>
                <center>
                <button className="Upload" onClick = {handleUpload} type="submit">Upload</button>
                <br/>
                <br/>
              
                <table className="table" style={{ backgroundColor: '#2ebbca' }}>
                    <caption><h2><center>Added Units</center> <button className='cleartable' onClick={handleClearTable}>Clear Table</button></h2> </caption> 
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Vehicle Type</th>
                          <th>Phone</th>
                          <th>VIN</th>
                          <th>Device Type</th>
                          <th>UID</th>
                          <th>License Plate</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{unitNames.map(un=> (<p key ={uuid()}>{un}</p>))}</td>
                          <td>{vehicletype.map(v=> (<p key ={uuid()}>{v}</p>))}</td>
                          <td>{phoneNumber.map(p=> (<p key ={uuid()}>{p}</p>))}</td>
                          <td>{unitVIN.map(vin=> (<p key ={uuid()}>{vin}</p>))}</td>
                          <td>{devicetype.map(d=> (<p key ={uuid()}>{d}</p>))}</td>
                          <td>{uid.map(ui=> (<p key ={uuid()}>{ui}</p>))}</td>
                          <td>{plate.map(pl=> (<p key ={uuid()}>{pl}</p>))}</td>
                        </tr>
                      </tbody>  
                  </table>
                  </center>
                  
                  <button className='download' onClick={handleDownloadClick}>Download</button><button className='download' onClick={handleClearLog}>Clear</button>

                  <div style={{ border: '1px solid black', padding: '5px', width:'74%', borderRadius: '5px', marginTop: '20px',marginLeft: 'auto',marginRight: 'auto',backgroundColor: '#2ebbca'}}>
                    <h3>Log </h3>
                    <div id="log">
                        {unitsCount.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                        {log.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div> 
                  </div>  

                  <div style={{ border: '1px solid black', padding: '5px', width:'74%', borderRadius: '5px', marginTop: '5px',marginLeft: 'auto',  marginRight: 'auto',backgroundColor: '#2ebbca'  }}>        
                    <h3>Status</h3>
                    <div>
                        {success.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>                  
                    <div>
                        {successUnitGroups.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>                             
                    <div>
                        {successCustom.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold' , fontSize: '10px'}}>{msg}</div>
                        ))}
                    </div>      
                    <div>
                        {successSensors.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>
                    <div>
                        {successHwConfig.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>
                    <div>
                        {successMileage.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>
                    <div>
                        {successEngine.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>
                    <div>
                        {successAdvanced.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>
                    <div>
                        {successTrip.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold' , fontSize: '10px'}}>{msg}</div>
                        ))}
                    </div>
                    <div>
                        {successEco.map((msg, index) => (
                            <div key={index} style={{fontWeight: 'bold', fontSize: '10px' }}>{msg}</div>
                        ))}
                    </div>
                    </div>    
                  <br/>
                  <br/>
                  <br/>

                  </>
                  
            )} 
      </div>
    ); 
  }

  export default FileReadComponent;