import React, { useState } from 'react';
import CreateAccount from './CreateAccount';
import ExistingAccount from './ExistingAccount';
import Header from "./Header";

function Accounts(props) 
{
    const [showDropdown, setShowDropdown] = useState(false);  // account and user dropdown will only be shown when showDropdown is true
    const [showForm, setShowForm] = useState(false); // only dispalying "create account form" after clicking "create account button" (after clicking it becomes true)
    const [allAccountNames, setAccountNames]= useState([]); // retrieved all accounts of logged user to create dropdown  and set it to Name and passing to ExistingAccount componnet
    const [billingPlan, setBillingPlan] = useState([]); // set billing plan of logged user and pass to createAccount component
    const [errors, setErrors] = useState([]);
    
    var remote= window.wialon.core.Remote.getInstance(); 
    function asyncRemoteCall(svc, param) 
    {
        return new Promise(function(resolve, reject) {
          remote.remoteCall(svc, param, function(err, data) {
                if (err) reject(err);
                else resolve(data);
            });
        });
    }
  
    const handleExistingAccount = async () => // clicking existing account button
    {   
        setShowDropdown(true); // account and user dropdown will only be shown when showDropdown is true
        let accounts = [];
        // get all accounts under current login   
        try
        { 
            // Modified this search condtion to only list accounts and filter resources.
            var unit = await asyncRemoteCall("core/search_items",{"spec":{"itemsType":"avl_resource","propName":"rel_is_account,sys_name","propValueMask":"1,*",
                            "sortType":"sys_name"},"force":1,"flags":1,"from":0,"to":0});
            //console.log(unit);
            if(unit.totalItemsCount === 0)
            {
                setErrors(prev => [...prev,"No accounts found for the currently logged-in user"]);
            }
            else
            {
                searchAccount(unit); 
            }            
        }
        catch(error)
        {
            //console.log("Failed to get all accounts under current login ", error);
            setErrors(prev => [...prev,"We're unable to retrieve your account list at this time. Please try again later."]);           
        }           
            
        // iterate though all the retrieved accounts and create drop down lis        
        function searchAccount(unit)
        {
            for(var i=0; i<unit.items.length; i++)
            {
                var accountName = unit.items[i].nm;
                accounts.push(accountName);
                //console.log(accounts);               
            }
            var sortedArray = accounts.sort(Intl.Collator().compare);
            //console.log(sortedArray);
            setAccountNames(sortedArray); // set generated accounts to "name" and iterate though it to genearte accounts dropdown
        }        
    }// end of handleExistingAccount (clicking "existing account" button)

  
    const handleCreateAccount = async ()=>  // on clicking - "create new account" button
    {
        setShowForm(true); // only dispalying "create account form" after clicking "create account button"        
        // get current account billing plan to show as dropdown when filling the create account form
        try
        {
            var unit = await asyncRemoteCall("core/get_account_data",{"type":1});
            //console.log(unit);
            if(unit === undefined)
            {
                setErrors(prev => [...prev,"We're unable to retrieve your billing plans because we can't find your account details. Please check your account and try again."]);
            }
            else
            {
                var subplans = unit.subPlans;
                //console.log(subplans);
                if(subplans.length === 0)
                {
                    setErrors(prev => [...prev,"We're unable to find any billing plans for your account."]);
                }
                else
                {
                    //Retriveing each billing plan from the array
                    var billingArray = subplans.map((item) => item);
                    //console.log(billingArray);
                    setBillingPlan(billingArray); // to pass to other components

                    /* the filteration is not needed anymore because this application is multitenant and therefore billing plan differs for LVM Manager and LVM Google manager logins.
                    // only showing billing plans -  standard and pro/pro+, because wlp applying to units for sensors and others are selected only using these billing plans
                    const plans = billingArray.filter((item) => {
                        return item === "LVMTech Standard 365" || item === "LVMTech Pro 365" || item === "LVMTech Pro+ 365";
                    });
                    //console.log(plans);
                    setBillingPlan(plans); // to pass to other components
                    */
                }
            }                  
        }
        catch(error)
        {
            //console.log("Failed to get billing plan under current account: ", error);
            setErrors(prev => [...prev,"We're unable to retrieve your billing plans at this time. Please try again later."])
        }         
    } // end of handleCreateAccount

    return (
        <div>
            {showDropdown ? (
                <ExistingAccount loggedUser ={props.loggedUser} allAccountNames={allAccountNames} excelFile={props.excelFile} setExcelFile={props.setExcelFile} onBackClick={() => { setShowDropdown(false);setErrors([]);}} />
                
            ) : showForm ? (
                <CreateAccount loggedUser ={props.loggedUser} billingPlan ={billingPlan} excelFile={props.excelFile} setExcelFile={props.setExcelFile} onBackClick={() => {setShowForm(false);setErrors([]);}}/> 
            ) : (
                <center>
                    <Header text="Onboarding Automation" />
                    <button className="existingBtn" onClick={handleExistingAccount} >Select An Existing Account</button>
                    <button className="createBtn" onClick ={handleCreateAccount}>Create A New Account</button>
                </center>                
            )}

            {errors.map((msg, index) => (
                 <div key={index} style={{ fontWeight: 'bold', padding: '10px' }}>{msg}</div>
            ))}
        </div>      
    );                   
}                
export default Accounts;