import React, { useState } from "react";
import Header from "./Header";

function FileComponent(props) 
{
  const [fileName, setFileName] = useState(null);

  const handleSelectFile = (event) => 
  {
      const file = event.target.files[0];
      props.setExcelFile(file);
      setFileName(event.target.files[0].name);
      event.target.value = null;
  };


  return (
    <div>
      <div style={{fontSize: '20px'}}>
      <center><Header text="Creating Units & Properties" /></center>
      </div>
        <div style={{ 
          float: "right",
            border: "1px solid #000", 
            padding: "10px", 
            width: "200px", 
            borderRadius: "5px",
            backgroundColor: '#2ebbca',
            marginRight: '5px'
          }}>
            <strong>Account:</strong> <em>{props.selectedAccountname} </em>
            <br/>
            <strong> User:</strong>  <em>{props.username}</em>
        </div>


        <div className="uploadfile">
          <h4><center>Upload Excel File: <input type="file" placeholder="Please select a file" accept=".xlsx,.xls" onChange={handleSelectFile}  /></center></h4>
            <center>
                {fileName && (
                  <>
                  <strong>Selected File: </strong> <em>{fileName}</em>
                  </>
                  
                )}
            </center>
            <br />
            <br />
        </div>
    </div>
  );
}

export default FileComponent;
